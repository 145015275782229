export async function BandScoreCalculation(reportObj) {
    if (reportObj.test_result.total_score === 20) {
        return {
            score: 9,
            description: "A band score of 9.0 reflects your high experience in using the English language and/or taking the IELTS test. You have full command of English language skills equivalent to that of native English speakers. You can comprehend both overall meanings, detailed information, and grammatical knowledge needed for the test. You also skillfully understand the vocabulary used in daily, formal, and academic contexts."
        }
    } if (reportObj.test_result.total_score === 19) {
        return {
            score: 8.5,
            description: "A band score of 8.5 reflects your high experience using the English language and/or taking the IELTS test. You have full command of English language skills almost equivalent to that of native English speakers. You can comprehend both overall meanings, detailed information, and grammatical knowledge needed for the test. You also skillfully understand the vocabulary used in daily, formal, and academic contexts with minimal mistakes"
        }
    } if (reportObj.test_result.total_score === 18) {
        return {
            score: 8,
            description: "A band score of 8.0 in IELTS reflects your high experience using the English language and/or taking the IELTS test. You have full command of English language skills almost equivalent to that of native English speakers. You can comprehend both overall meanings, detailed information, and grammatical knowledge needed for the test.  You also skillfully understand the vocabulary used in daily, formal, and academic contexts. Practice test skills and pay closer attention to grammar, context clues, and details; you could even achieve a band score of 8.5 or 9.0!"
        }
    } if (reportObj.test_result.total_score === 17) {
        return {
            score: 7.5,
            description: "A band score of 7.5 reflects your high experience using the English language and/or taking the IELTS test with minor mistakes. You have a good command of English language skills that could be improved by exposing yourself to a broader range of vocabulary and practices. However, you handle both overall meanings and detailed information of dialogues, detailed information, and grammatical knowledge for different communicative skills included in the test with good skill."
        }
    } if (reportObj.test_result.total_score === 16 || reportObj.test_result.total_score === 15) {
        return {
            score: 7,
            description: "A band score of 7.0 reflects your good familiarity with using the English language and/or taking the IELTS test. You have a good command of English language skills that could be improved by exposing yourself to a broader range of vocabulary and practices. However, you handle both overall meanings and detailed information of dialogues, detailed information, and grammatical knowledge  for different communicative skills needed for the test. Although you may need more experience with working on the vocabulary in the broader context, particularly those in formal and academic settings, you should be able to earn 0.5 to 1 or higher with more constant practice on the IELTS test."
        }
    } if (reportObj.test_result.total_score === 14) {
        return {
            score: 6.5,
            description: "A band score of 6.5 reflects your good familiarity with using the English language in less formal contexts. However, you may not have been familiar enough with the IELTS test, including format, types of questions, range of vocabulary, and time management. You have a good command of English and skills that could be improved by exposing yourself to a broader range of vocabulary and practices. While relying on your competent experience with English in a casual context, introducing yourself to an academic format that you are less familiar with can improve your overall band score. Most test-takers with an overall band score of 6.5 perform better on listening and reading as the test formats are more manageable than writing and speaking. Hence, maximising your score from what you are good at can improve an overall band score. However, you have done a good job so far. Most test-takers who can earn slightly higher than 6.5  tend to find it much easier to improve their overall band score. Once you get the hang of it, there should be no turning back!"
        }
    } if (reportObj.test_result.total_score === 13 || reportObj.test_result.total_score === 12) {
        return {
            score: 6,
            description: "A band score of 6.0 reflects your good familiarity with using the English language in less formal contexts. However, you may not have been exposed enough to the IELTS test, including format, types of questions, range of vocabulary, and time management. You have a good command of English language skills for most skills that may not include complex ideas or vocabulary. For example, you may understand most of the texts or speeches but did not understand the contexts' detailed information. You could manage conversations, reading, or writing in more casual situations but may still find information in more formal or academic settings uncomfortable. While relying on your competent experience with English in informal contexts alone, introducing yourself to an academic format that you are less familiar with can improve your overall band score. Most test-takers with an overall band score of 6.0 perform better on listening and reading as the test formats are more manageable than writing and speaking. Hence, maximising your score from what you are good at can improve an overall band score. However, you have done a good job so far. Most test-takers who can earn slightly higher than 6.0 tend to find it much easier to improve their overall band score. Once you get the hang of it, there should be no turning back!"
        }
    } if (reportObj.test_result.total_score === 11 || reportObj.test_result.total_score === 10) {
        return {
            score: 5.5,
            description: "A band score of 5.5 reflects your moderate to good familiarity with using the English language in less formal contexts. However, you may not have enough experience with the IELTS test, including format, types of questions, range of vocabulary, and time management. You have a fair command of English language skills that may not include complex sentences or vocabulary. Although you may understand some of the overall meaning of the contents, you did not understand the detailed information in the contexts. Besides practising English from daily contexts, expanding your vocabulary range can improve your overall skills, especially in listening and reading. Most test-takers with an overall band score of 5.5 may still lack confidence in recognising words, spelling, a wide range of vocabulary, and grammar. In addition, they may still find paying attention to long tests challenging. However, making sure that you are familiar with the test format and needed vocabulary can improve your score on listening and reading, which will be a good start for a higher overall band score. You have done a wise job so far. Most test-takers with a band score of 5.5 can reach .5 or 1 band score higher with a bit more practice. Remember that earning a high band score in listening and reading is likely to drastically improve your overall band score!"
        }
    } if (reportObj.test_result.total_score === 9 || reportObj.test_result.total_score === 8) {
        return {
            score: 5,
            description: "A band score of 5.0 reflects your moderate familiarity with using the English language in less formal contexts. However, you may not have enough experience with the IELTS test, including format, types of questions, range of vocabulary, and time management. You have a fair command of English language skills that may not include complex sentences or vocabulary. You may understand some of the meaning of the content in the test but did not understand detailed information in the contexts. Besides practising English from daily contexts, expanding your vocabulary range can improve your overall skills, especially in listening and reading. Most test-takers with an overall band score of 5.0 may still lack confidence in recognising words, spelling, a wide range of vocabulary, and grammar. In addition, they may still find paying attention to long tests challenging. However, making sure that you are familiar with the test format and needed vocabulary can improve your score on listening and reading, which will be a good start for a higher overall band score. You have done a wise job so far. Most test-takers with a band score of 5.0 can reach .5 or 1 band score higher with a bit more practice."
        }
    } if (reportObj.test_result.total_score === 7 || reportObj.test_result.total_score === 6) {
        return {
            score: 4.5,
            description: "A band score of 4.5 reflects your moderate familiarity with using the English language in less formal contexts. However, you may not have enough experience with the IELTS test, including format, types of questions, range of vocabulary, and time management."
        }
    } if (reportObj.test_result.total_score === 5) {
        return {
            score: 4,
            description: "A band score of 4.0 reflects your moderate familiarity with using the English language in less formal contexts. However, you may not have adequate experience with the IELTS test, including format, types of questions, range of vocabulary, and time management."
        }
    } if (reportObj.test_result.total_score === 4) {
        return {
            score: 3.5,
            description: "A band score of 3.5 shows that you have limited experience with the English language in general, in which you may only understand common words used in the frequently heard contexts. However, it could also be because you lack experience with the IELTS test, including format, types of questions, range of vocabulary, and time management."
        }
    } if (reportObj.test_result.total_score === 3) {
        return {
            score: 3,
            description: "A band score of 3.0 shows that you have limited experience with the English language in general, in which you may only understand common words used in the frequently heard contexts. While you understand some sentences, hearing them together in more extended conversations, speeches, and texts could be challenging."
        }
    } if (reportObj.test_result.total_score === 2) {
        return {
            score: 2.5,
            description: "A band score of 2.5 shows that you have extreme difficulty understanding English in different settings. Although it could also be because you lack experience with the IELTS test, including format, types of questions, range of vocabulary, and time management, you might start engaging more with the English language in your daily life."
        }
    } if (reportObj.test_result.total_score < 2) {
        return {
            score: 0,
            description: "You did not attempt to answer the questions."
        }
    }
}

export async function BandScoreCalculationByPoint(score) {
    if (score === 20) {
        return 9
    } if (score === 19) {
        return 8.5
    } if (score === 18) {
        return 8
    } if (score === 17) {
        return 7.5
    } if (score === 16 || score === 15) {
        return 7
    } if (score === 14) {
        return 6.5
    } if (score === 13 || score === 12) {
        return 6
    } if (score === 11 || score === 10) {
        return 5.5
    } if (score === 9 || score === 8) {
        return 5
    } if (score === 7 || score === 6) {
        return 4.5
    } if (score === 5) {
        return 4
    } if (score === 4) {
        return 3.5
    } if (score === 3) {
        return 3
    } if (score === 2) {
        return 2.5
    } if (score < 2) {
        return 0
    }
}

export function CapitalizeFirstLetter(string) {
    const result = string.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}

export function SplitPascalCaseToString(s) {
    return CapitalizeFirstLetter(s.split(/(?=[A-Z])/).join(' '));
}

export async function BMATScoreCalculation(score, section) {
    if (section === 1) {
        if (score === 0) {
            return {
                score: 0
            }
        }
    }
}

export function RoundBand(score) {
    const nearest = Math.round(score * 2) / 2;
    if (Math.abs(nearest - score) >= 0.125) {
        return Math.ceil(nearest)
    }

    return Math.round(score * 2) / 2;
}

export function MapBandScoreDescription(bandScore) {
    if (bandScore === 9) {
        return "A band score of 9.0 reflects your high experience in using the English language and/or taking the IELTS  test. You have full command of English language skills equivalent to that of native English speakers. You can comprehend both overall meanings, detailed information, and grammatical knowledge needed for the test. You also skillfully understand the vocabulary used in daily, formal, and academic contexts."
    } if (bandScore === 8.5) {
        return "A band score of 8.5 reflects your high experience using the English language and/or taking the IELTS test. You have full command of English language skills almost equivalent to that of native English speakers. You can comprehend both overall meanings, detailed information, and grammatical knowledge needed for the test. You also skillfully understand the vocabulary used in daily, formal, and academic contexts with minimal mistakes.";
    } if (bandScore === 8) {
        return "A band score of 8.0 in IELTS reflects your high experience using the English language and/or taking the IELTS test. You have full command of English language skills almost equivalent to that of native English speakers. You can comprehend both overall meanings, detailed information, and grammatical knowledge needed for the test.  You also skillfully understand the vocabulary used in daily, formal, and academic contexts. Practice test skills and pay closer attention to grammar, context clues, and details; you could even achieve a band score of 8.5 or 9.0!";
    } if (bandScore === 7.5) {
        return "A band score of 7.5 reflects your high experience using the English language and/or taking the IELTS test with minor mistakes. You have a good command of English language skills that could be improved by exposing yourself to a broader range of vocabulary and practices. However, you handle both overall meanings and detailed information of dialogues, detailed information, and grammatical knowledge for different communicative skills included in the test with good skill.";
    } if (bandScore === 7) {
        return "A band score of 7.0 reflects your good familiarity with using the English language and/or taking the IELTS test. You have a good command of English language skills that could be improved by exposing yourself to a broader range of vocabulary and practices. However, you handle both overall meanings and detailed information of dialogues, detailed information, and grammatical knowledge  for different communicative skills needed for the test. Although you may need more experience with working on the vocabulary in the broader context, particularly those in formal and academic settings, you should be able to earn 0.5 to 1 or higher with more constant practice on the IELTS test.";
    } if (bandScore === 6.5) {
        return "A band score of 6.5 reflects your good familiarity with using the English language in less formal contexts. However, you may not have been familiar enough with the IELTS test, including format, types of questions, range of vocabulary, and time management. You have a good command of English and skills that could be improved by exposing yourself to a broader range of vocabulary and practices. While relying on your competent experience with English in a casual context, introducing yourself to an academic format that you are less familiar with can improve your overall band score. Most test-takers with an overall band score of 6.5 perform better on listening and reading as the test formats are more manageable than writing and speaking. Hence, maximising your score from what you are good at can improve an overall band score. However, you have done a good job so far. Most test-takers who can earn slightly higher than 6.5  tend to find it much easier to improve their overall band score. Once you get the hang of it, there should be no turning back!";
    } if (bandScore === 6) {
        return "A band score of 6.0 reflects your good familiarity with using the English language in less formal contexts. However, you may not have been exposed enough to the IELTS test, including format, types of questions, range of vocabulary, and time management. You have a good command of English language skills for most skills that may not include complex ideas or vocabulary. For example, you may understand most of the texts or speeches but did not understand the contexts' detailed information. You could manage conversations, reading, or writing in more casual situations but may still find information in more formal or academic settings uncomfortable. While relying on your competent experience with English in informal contexts alone, introducing yourself to an academic format that you are less familiar with can improve your overall band score. Most test-takers with an overall band score of 6.0 perform better on listening and reading as the test formats are more manageable than writing and speaking. Hence, maximising your score from what you are good at can improve an overall band score. However, you have done a good job so far. Most test-takers who can earn slightly higher than 6.0 tend to find it much easier to improve their overall band score. Once you get the hang of it, there should be no turning back!";
    } if (bandScore === 5.5) {
        return "A band score of 5.5 reflects your moderate to good familiarity with using the English language in less formal contexts. However, you may not have enough experience with the IELTS test, including format, types of questions, range of vocabulary, and time management. You have a fair command of English language skills that may not include complex sentences or vocabulary. Although you may understand some of the overall meaning of the contents, you did not understand the detailed information in the contexts. Besides practising English from daily contexts, expanding your vocabulary range can improve your overall skills, especially in listening and reading. Most test-takers with an overall band score of 5.5 may still lack confidence in recognising words, spelling, a wide range of vocabulary, and grammar. In addition, they may still find paying attention to long tests challenging. However, making sure that you are familiar with the test format and needed vocabulary can improve your score on listening and reading, which will be a good start for a higher overall band score. You have done a wise job so far. Most test-takers with a band score of 5.5 can reach .5 or 1 band score higher with a bit more practice. Remember that earning a high band score in listening and reading is likely to drastically improve your overall band score!";
    } if (bandScore === 5) {
        return "A band score of 5.0 reflects your moderate familiarity with using the English language in less formal contexts. However, you may not have enough experience with the IELTS test, including format, types of questions, range of vocabulary, and time management. You have a fair command of English language skills that may not include complex sentences or vocabulary. You may understand some of the meaning of the content in the test but did not understand detailed information in the contexts. Besides practising English from daily contexts, expanding your vocabulary range can improve your overall skills, especially in listening and reading. Most test-takers with an overall band score of 5.0 may still lack confidence in recognising words, spelling, a wide range of vocabulary, and grammar. In addition, they may still find paying attention to long tests challenging. However, making sure that you are familiar with the test format and needed vocabulary can improve your score on listening and reading, which will be a good start for a higher overall band score. You have done a wise job so far. Most test-takers with a band score of 5.0 can reach .5 or 1 band score higher with a bit more practice.";
    } if (bandScore === 4.5) {
        return "A band score of 4.5 reflects your moderate familiarity with using the English language in less formal contexts. However, you may not have enough experience with the IELTS test, including format, types of questions, range of vocabulary, and time management.";
    } if (bandScore === 4) {
        return "A band score of 4.0 reflects your moderate familiarity with using the English language in less formal contexts. However, you may not have adequate experience with the IELTS test, including format, types of questions, range of vocabulary, and time management.";
    } if (bandScore === 3.5) {
        return "A band score of 3.5 shows that you have limited experience with the English language in general, in which you may only understand common words used in the frequently heard contexts. However, it could also be because you lack experience with the IELTS test, including format, types of questions, range of vocabulary, and time management.";
    } if (bandScore === 3) {
        return "A band score of 3.0 shows that you have limited experience with the English language in general, in which you may only understand common words used in the frequently heard contexts. While you understand some sentences, hearing them together in more extended conversations, speeches, and texts could be challenging.";
    } if (bandScore === 2.5) {
        return "A band score of 2.5 shows that you have extreme difficulty understanding English in different settings. Although it could also be because you lack experience with the IELTS test, including format, types of questions, range of vocabulary, and time management, you might start engaging more with the English language in your daily life.";
    } if (bandScore === 2) {
        return "A band score of 2.0 shows that you have extreme difficulty using and understanding English in different settings. Although it could also be because you lack experience with the IELTS test, including format, types of questions, range of vocabulary, and time management, you might start improving your skill by including more English language in your daily life.";
    } if (bandScore === 1.5) {
        return "Either you did not complete the test, or you have no ability to use the English language. Start with a small step and be patient; you will see the improvement.";
    } if (bandScore === 1) {
        return "Either you did not complete the test, or you have no ability to use the English language. Start with a small step and be patient; you will see the improvement.";
    } if (bandScore === 0) {
        return "You did not attempt to answer the questions.";
    }
}

export function CheckDisplaySection(section, testType) {
    if (testType === 'listening') {
        return (section.raw_score >= 0 && section.raw_score <= 10) ? true : false
    } if (testType === 'reading') {
        if (section.no === 'InformationIdentification') {
            return (section.raw_score >= 0 && section.raw_score <= 7) ? true : false
        } if (section.no === 'ListOfHeadings') {
            return (section.raw_score >= 0 && section.raw_score <= 4) ? true : false
        } if (section.no === 'NoteAndSummaryCompletion') {
            return (section.raw_score >= 0 && section.raw_score <= 11) ? true : false
        } if (section.no === 'MatchingSentenceEndings') {
            return (section.raw_score >= 0 && section.raw_score <= 4) ? true : false
        } if (section.no === 'MatchingFeatures') {
            return (section.raw_score >= 0 && section.raw_score <= 3) ? true : false
        } if (section.no === 'MultipleChoices') {
            return (section.raw_score >= 0 && section.raw_score <= 6) ? true : false
        }

        return false;
    }

    return true;
}

export function MapReadingBandScore(section, testType, description) {
    if (testType === 'reading') {
        if (section.no === 'InformationIdentification') {
            return (section.raw_score >= 0 && section.raw_score <= 7) ? "This type of question is primarily unique to the IELTS reading test. This section tests the ability to distinguish the writers’ perspectives and unstated claims, reflecting one of the essential reading skills in academic settings. Despite its ultimate purpose, many test takers have trouble achieving high scores in this section because they cannot recognise the different words that share the same definitions. Besides, they may have relied on their knowledge of the topic to answer the question. However, your performance indicates how you may have not adequately approached the passages and the questions with clear underlined ideas. It is also worth noting that you must solely rely on the information given to identify the information on the IELTS reading test. Apart from reinforcing your vocabulary, practising how to differentiate facts and opinions can be an excellent help to your reading band score!" : false
        } if (section.no === 'ListOfHeadings') {
            return (section.raw_score >= 0 && section.raw_score <= 4) ? "Comprehending and classifying main topics, topic sentences, and underlined details is an essential skill for any IELTS test-takers. Generally, there should be at least one set of question types within the 3 passages that primarily aim to assess this skill. The outcome of your performance suggests that you may lack proper time management and experience in prioritising written ideas and support. While most test-takers who underperform questions on this skill set share similar problems approaching the main focus of the passage and the paragraphs, they often get tricked by the test’s format and synonyms. Hence, strengthening your understanding of paragraph structures and academic writing will help you better identify the writers’ structures of their passages and paragraphs, which will significantly improve your score on this section." : false
        } if (section.no === 'NoteAndSummaryCompletion') {
            return (section.raw_score >= 0 && section.raw_score <= 11) ? "The IELTS test assesses this skill set using questions from different formats. Therefore, it usually makes up a large portion of the reading test. The result shows that you may still lack a wide range of essential vocabulary needed to identify and locate the information from the passages. Although most test takers understand the passage, detecting the keywords on either questions or passages still does not come naturally and systematically. Instead of depending solely on the vocabulary and meaning of texts, using grammatical knowledge on the part of speech can drastically save you more time and help with this section's decision-making." : false
        } if (section.no === 'MatchingSentenceEndings') {
            return (section.raw_score >= 0 && section.raw_score <= 4) ? "The result suggests you may lack experience in integrating both grammatical knowledge and reasoning required for the IELTS test. Besides testing your understanding of the passage and the ability to locate the information, this type of question also requires decision-making skills and the ability to combine complete sentences using word clues, logic, and grammatical knowledge. Although this test section is not as complex as others, most test-takers spend longer time completing it than they should. Hence, learning to prioritise and focus on when working on this section will save you more time and effort." : false
        } if (section.no === 'MatchingFeatures') {
            return (section.raw_score >= 0 && section.raw_score <= 3) ? "Matching features is one of the essential methods in reading as it requires the reader to find the specific information without having to always understand the main idea. Most test-takers who do not perform well in this the task did not know how to approach them properly questions, which could also be similar to you. Your score reveals that you may need to shift your focus analysing each type of question before starting your work on the passage. By far, many successful IELTS test takers have found that Matching Features is one of the easiest types of questions. If they could do it, so can you!" : false
        } if (section.no === 'MultipleChoices') {
            return (section.raw_score >= 0 && section.raw_score <= 6) ? "The multiple-choice section is considered one of the most straightforward formats of the IELTS reading test. It precisely tests your ability to pinpoint both specific and detailed information. As a result, you often find a clear key point in each question. The test result shows that, like many test-takers, you might have been tricked by the word choices or format written in the tests. Similarly to many other question types, you need to prioritise the keywords used to locate your correct answers in the passage. However, your score could be a result of lacking a wide range of common academic vocabulary. Spending your free time outside the test to read the English texts in detail will help you so much with that." : false
        }
    }

    return description;
}

export function BmatScoreMappingSectionOne(score) {
    if (score <= 3) {
        return 1
    } if (score === 4) {
        return 1.3
    }if (score === 5) {
        return 1.6
    } if (score === 6) {
        return 1.9
    } if (score === 7) {
        return 2.2
    } if (score === 8) {
        return 2.5
    } if (score === 9) {
        return 2.7
    } if (score === 10) {
        return 2.9
    } if (score === 11) {
        return 3.1
    } if (score === 12) {
        return 3.3
    } if (score === 13) {
        return 3.5
    } if (score === 14) {
        return 3.7
    } if (score === 15) {
        return 3.9
    } if (score === 16) {
        return 4.1
    } if (score === 17) {
        return 4.3
    } if (score === 18) {
        return 4.5
    } if (score === 19) {
        return 4.6
    } if (score === 20) {
        return 4.8
    } if (score === 21) {
        return 5
    } if (score === 22) {
        return 5.2
    } if (score === 23) {
        return 5.4
    } if (score === 24) {
        return 5.7
    } if (score === 25) {
        return 5.9
    } if (score === 26) {
        return 6.2
    } if (score === 27) {
        return 6.5
    } if (score === 28) {
        return 6.8
    } if (score === 29) {
        return 7.3
    } if (score === 30) {
        return 7.9
    } if (score === 31) {
        return 8.8
    } if (score === 32) {
        return 9
    }
} 

export function BmatScoreMappingSectionTwo(score) {
    if (score <= 2) {
        return 1
    } if (score === 3) {
        return 1.6
    } if (score === 4) {
        return 2.1
    } if (score === 5) {
        return 2.6
    } if (score === 6) {
        return 3
    } if (score === 7) {
        return 3.3
    } if (score === 8) {
        return 3.7
    } if (score === 9) {
        return 4
    } if (score === 10) {
        return 4.3
    } if (score === 11) {
        return 4.6
    } if (score === 12) {
        return 4.8
    } if (score === 13) {
        return 5.1
    } if (score === 14) {
        return 5.4
    } if (score === 15) {
        return 5.7
    } if (score === 16) {
        return 6
    } if (score === 17) {
        return 6.3
    } if (score === 18) {
        return 6.6
    } if (score === 19) {
        return 6.9
    } if (score === 20) {
        return 7.3
    } if (score === 21) {
        return 7.7
    } if (score === 22) {
        return 8.1
    } if (score === 23) {
        return 8.6
    } if (score === 24) {
        return 9
    } if (score === 25) {
        return 9
    } if (score === 26) {
        return 9
    } if (score === 27) {
        return 9
    }
} 
