//React
import { Component } from "react";
import { compose } from "recompose";

//Material UI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createMuiTheme, withStyles } from "@material-ui/core/styles";
import AccessAlarmRoundedIcon from "@material-ui/icons/AccessAlarmRounded";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

//import components
import PageBackdrop from "../../../../main/PageBackdrop";
import UserInfoPage from "../../writing/UserInfoPage";
import InstructionsPage from "../instructionPage/writing/InstructionsPage";

//PDF File
import htmlToPdfmake from "html-to-pdfmake";
import jsPDF from "jspdf";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import WritingReport from "../../writing/WritingReport";

//Icons
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';

//react-router-dom
import { Link } from "react-router-dom";
import { CreateNewNotification } from "../../../../../services/notificationServices";
import LoadingDialog from "../loadingDialog/LoadingDialog";

import { GetUserDetail } from "../../../../../services/userServices";

const axios = require("axios").default;
const FormData = require("form-data");
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paperRoot: {
    padding: theme.spacing(2),
    textAlign: "left",
  },
  paperItem: {
    padding: theme.spacing(2),
    textAlign: "left",
    height: "810px",
    maxHeight: "810px",
    overflow: "auto",
  },
  testAlignLeft: {
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginTop: {
    marginTop: 20,
  },
  margin: {
    margin: 5,
  },
});

//FONT RELATED
const theme = createMuiTheme({
  typography: {
    fontFamily: ["Noto Sans"].join(","),
  },
});

class TestWriting extends Component {
  constructor() {
    super();
    this.state = {
      questionNo: "",
      answers: [],
      highlightedText: [],
      reviewedQuestion: [],
      reviewed: false,
      isLoading: false,
      readyToShow: false,
      testObject: null,
      totalScore: {},
      isDone: false,
      isConfirmDone: false,
      timer: 3600,
      timerSeconds: 0,
      window: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
      grammarErrorCount: {
        grammar: 0,
        spelling: 0,
      },
      windowSize: "",
      isStartTest: false,
      isCheckUserInfo: false,
      isReadInstruction: true,
      statusText: "",
      loadingPercentage: 0,
      isOpenLoadingDialog: false,
      reportCreated: false,
      focusedQuestion: 1,
      fontSize: 1,
      fontSizeMap: 'md',
      textAreaSize: {
        sm: '0.8rem',
        md: '1rem',
        lg: '1.2rem'
      }
    };
  }

  handleResize = (event) => {
    this.setState({
      window: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize);
    this.checkSession();
  };

  checkSession = async () => {
    if (this.props.session !== null) {
      await this.setState(this.props.session);
      this.props.keepCurrentSession(this.state);
    } else {
      window.addEventListener("resize", this.handleResize);
      await this.handleGetTest();

      const today = new Date();
      await this.setState({
        currentDate: today.toDateString(),
        currentDateWithFormat: today.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      });

      this.props.keepCurrentSession(this.state);
    }
  }

  handleGetTest = async () => {
    const getTest_url =
      api_base_url + "/api/test/random?test_type=IELTS&is_free=false";

    try {
      this.handleLoading();

      const testObj = await axios.get(getTest_url, {
        headers: {
          Authorization:
            "bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      });

      let filteredTest = await testObj.data.test.parts.filter(
        (parts) => parts.part_type === "WRITING"
      );
      await this.setState({
        test_id: testObj.data.test.test_id,
        currentTestObject: filteredTest[0],
        currentQuestion: 1,
        fullTestObj: filteredTest[0],
        numPart: filteredTest.length,
      });
      this.handleCloseLoading();
    } catch (error) {
      this.handleCloseLoading();
      console.log(error);
    }
  };

  handleUserInfoChecked = () => {
    this.setState({
      isStartTest: false,
      isCheckUserInfo: false,
      isReadInstruction: true,
    });
  };

  handleDoneReadInstruction = () => {
    this.setState({
      isStartTest: true,
      isCheckUserInfo: false,
      isReadInstruction: false,
    });

    clearInterval(this.myInterval);
    this.myInterval = setInterval(() => {
      if (this.state.timer > 0) {
        if (this.state.timerSeconds === 0) {
          this.setState({
            timerSeconds: 60,
          });
        }

        this.setState((prevState) => ({
          timer: prevState.timer - 1,
          timerSeconds: this.state.timerSeconds - 1,
        }));
      } if (this.state.timer === 60 || this.state.timer === 59) {
        GetUserDetail(JSON.parse(localStorage.getItem("user")).token);
      }
    }, 1000);
  };

  //========================= TIMER =========================
  handleAddLeadingZero = (num) => {
    if (num < 10) {
      return "0" + num;
    } else if (num >= 10) {
      return "" + num;
    }
  };
  //========================= TIMER =========================

  //======================== BEGIN BACKDROP CONTROL ==============================
  handleCloseLoading = () => {
    this.setState({
      isLoading: false,
    });
  };

  handleLoading = () => {
    this.setState({
      isLoading: true,
    });
  };
  //======================== END BACKDROP CONTROL ===============================

  onClickFontSize = (size) => {
    if (size === "sm") {
      this.setState({
        fontSizeMap: "sm"
      });
    } if (size === "md") {
      this.setState({
        fontSizeMap: "lg"
      });
    } if (size === "lg") {
      this.setState({
        fontSizeMap: "lg"
      });
    }

    return;
  }

  handleFontSize = (type) => {
    if (type === 'plus') {
      this.setState({
        fontSize: this.state.fontSize + 0.1
      })
    } else if (type === 'minus') {
      this.setState({
        fontSize: this.state.fontSize - 0.1
      })
    }
  }

  handleRestTextSize = () => {
    this.setState({
      fontSize: 1
    });
  }

  handleChange = (event) => {
    const question = event.target.name;
    this.state.answers[question] = event.target.value;

    this.setState({
      answers: this.state.answers,
    });
  };

  handleChangeReviewed = () => {
    this.setState({
      reviewed: !this.state.reviewed,
    });
  };

  handleReviewQuestion = (event) => {
    this.state.reviewedQuestion[Number(this.state.focusedQuestion)] = event.target.checked;
    this.setState({
      reviewedQuestion: this.state.reviewedQuestion
    });
  }

  handleConfirmDoneTestDialog = async () => {
    this.props.keepCurrentSession(this.state);
    if (this.state.answers.length !== 2) {
      this.setState({
        isNotAllQuestion: true,
      });
    } else if (this.state.answers.length === 2) {
      this.setState({
        isNotAllQuestion: false,
      });

      GetUserDetail(JSON.parse(localStorage.getItem("user")).token);
    }

    this.setState({
      isConfirmDone: true,
    });
  };

  handleCloseConfirmDoneTestDialog = () => {
    this.setState({
      isConfirmDone: false,
    });
  };

  //PDF
  printDocument = () => {
    const doc = new jsPDF();

    //get table html
    const pdfTable = document.getElementById("divToPrint");
    //html to pdf format
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      imagesByReference: true,
    });

    const documentDefinition = {
      content: html.content,
      images: html.images,
      pageBreakBefore: function (currentNode) {
        return (
          currentNode.style &&
          currentNode.style.indexOf("pdf-pagebreak-before") > -1
        );
      },
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();
  };

  handleGrammarChecking = async (inputText) => {
    let retries = 2;

    try {

      let highlightedText = [];
      let editedString = [];
      let grammarErrorCount = {
        grammar: 0,
        spelling: 0,
      };
      let json = {
        text: inputText,
      };

      const res = await axios({
        method: "post",
        url: "https://chatter-funky-busby.glitch.me/ielts/writing/tests/submit",
        headers: {
          "Content-Type": "application/json",
        },
        data: json,
      });


      if (res.data.grammar.response.errors.length === 0) {
        return {
          lastEdit: inputText,
          highlightedText: [],
          grammarErrorCount: {
            grammar: 0,
            spelling: 0,
          },
        };
      } else if (res.data.grammar.response.errors.length > 0) {
        await res.data.grammar.response.errors.map((error) => {
          let errorObj = {
            start: error.offset,
            length: error.length,
            bad: error.bad,
            type: error.type,
          };

          if (error.type === "grammar") {
            grammarErrorCount = {
              grammar: grammarErrorCount.grammar + 1,
              spelling: grammarErrorCount.spelling,
            };
          }
          if (error.type === "spelling") {
            grammarErrorCount = {
              grammar: grammarErrorCount.grammar,
              spelling: grammarErrorCount.spelling + 1,
            };
          }

          highlightedText.push(errorObj);
        });


        await highlightedText.map((indexRange, index) => {
          
          if (index === 0) {
            let startText = inputText.substring(0, indexRange.start);
            let endText = highlightedText[index + 1] === undefined ? "" : inputText.substring(
              highlightedText[index].start + highlightedText[index].length,
              highlightedText[index + 1].start
            );
            editedString.push(startText);
            editedString.push(
              <span
                style={{
                  backgroundColor:
                    indexRange.type === "grammar"
                      ? "pink"
                      : "yellow" /*textDecoration: 'underline', textDecorationColor: indexRange.type === 'grammar' ? 'red' : 'orange'*/,
                }}
              >
                {highlightedText[index].bad}
              </span>
            );
            editedString.push(endText);
          }

          if (highlightedText[index + 1] === undefined) {
            let endText = inputText.substring(
              highlightedText[index].start + highlightedText[index].length
            );
            editedString.push(
              <span
                style={{
                  backgroundColor:
                    indexRange.type === "grammar"
                      ? "pink"
                      : "yellow" /*textDecoration: 'underline', textDecorationColor: indexRange.type === 'grammar' ? 'red' : 'orange'*/,
                }}
              >
                {highlightedText[index].bad}
              </span>
            );
            editedString.push(endText);
          }

          if (index !== 0 && highlightedText[index + 1] !== undefined) {
            let endText = inputText.substring(
              highlightedText[index].start + highlightedText[index].length,
              highlightedText[index + 1].start
            );
            editedString.push(
              <span
                style={{
                  backgroundColor:
                    indexRange.type === "grammar"
                      ? "pink"
                      : "yellow" /*textDecoration: 'underline', textDecorationColor: indexRange.type === 'grammar' ? 'red' : 'orange'*/,
                }}
              >
                {highlightedText[index].bad}
              </span>
            );
            editedString.push(endText);
          }
        });

        const lastEdit = (
          <div>{editedString.map((component) => component)}</div>
        );

        // console.log({
        //   lastEdit: lastEdit,
        //   highlightedText: highlightedText,
        //   grammarErrorCount: grammarErrorCount,
        //   grammarCheckObj: res.data.grammar.response,
        //   readabilityObj: res.data.readability.response,
        // });

        return {
          lastEdit: lastEdit,
          highlightedText: highlightedText,
          grammarErrorCount: grammarErrorCount,
          grammarCheckObj: res.data.grammar.response,
          readabilityObj: res.data.readability.response,
        };
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleTestPDF = async (submitter, submitterUsername) => {
    this.handleLoading();

    const q1 = await this.handleGrammarChecking(this.state.answers[0]);
    const q2 = await this.handleGrammarChecking(this.state.answers[1]);
    await this.setState({
      grammarErrorCountQ1: q1.grammarErrorCount,
      grammarErrorCountQ2: q2.grammarErrorCount,
      highlightedTextQ1: q1.highlightedText,
      highlightedTextQ2: q2.highlightedText,
      lastEditQ1: q1.lastEdit,
      lastEditQ2: q2.lastEdit,
      grammarCheckObjQ1: q1.grammarCheckObj,
      grammarCheckObjQ2: q2.grammarCheckObj,
      readabilityObjQ1: q1.readabilityObj,
      readabilityObjQ2: q2.readabilityObj,
    });

    console.log({
      userObj: JSON.parse(localStorage.getItem("user")),
      currentDate: this.state.currentDate,
      fullTestObj: this.state.fullTestObj,
      answerQ1Obj:
        this.state.answers[0] === undefined ? "" : this.state.answers[0]
      ,
      answerQ2Obj:
        this.state.answers[1] === undefined ? "" : this.state.answers[1],

      grammarCheckObj:
        this.state.grammarCheckObj === undefined
          ? {}
          : this.state.grammarCheckObj,

      grammarErrorCountQ1: this.state.grammarErrorCountQ1,
      grammarErrorCountQ2: this.state.grammarErrorCountQ2,
      highlightedTextQ1: this.state.highlightedTextQ1,
      highlightedTextQ2: this.state.highlightedTextQ2,
      lastEditQ1: this.state.lastEditQ1,
      lastEditQ2: this.state.lastEditQ2,
      grammarCheckObjQ1: this.state.grammarCheckObjQ1,
      grammarCheckObjQ2: this.state.grammarCheckObjQ2,
      readabilityObjQ1: this.state.readabilityObjQ1,
      readabilityObjQ2: this.state.readabilityObjQ2,
    });

    this.setState({
      readyToShow: true,
      grammarChecked: true,
    });

    //get table html
    const pdfTable = document.getElementById("divToPrint");
    //html to pdf format
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      imagesByReference: true,
    });

    const documentDefinition = {
      content: [
        {
          columns: [
            [
              {
                text: 'IELTS Writing',
                fontSize: 18,
                color: "#1b0b7a"
              },
              {
                text: `Test date: ${this.state.currentDate}`, margin: [0, 20, 0, 0]
              }
              ,
              {
                text: `Student name: ${JSON.parse(localStorage.getItem("user")).name}`
              }
            ],
            [
              {
                image: 'image1',
                width: 30,
                height: 30,
                style: 'image'
              },
              {
                image: 'image2',
                width: 80,
                height: 80,
                style: 'image',
                margin: [0, 10, 0, 0]
              }
            ],
          ]
        },
        {
          text: "Task 1",
          bold: true
        },
        {
          text: `Question: ${this.state.fullTestObj.sections[0].questions[0].question}`,
          margin: [0, 5, 0, 0]
        },
        {
          image: 'image3',
          width: 250,
          margin: [0, 10, 0, 0],
          alignment: 'center'
        },
        {
          text: 'Answer',
          margin: [0, 10, 0, 0],
          bold: true
        },
        this.state.answers[0]?.length > 0 ? html.content[1] : { text: '' },
        html.content[2],
        {
          margin: [0, 15, 0, 15],
          table: {
            widths: [150, 250],
            body: [
              ['Possible Improvements', 'Metrics'],
              [
                {
                  stack: [
                    {
                      ul: [
                        `Grammar: ${this.state.grammarErrorCountQ1.grammar}`,
                        `Spelling: ${this.state.grammarErrorCountQ1.spelling}`,
                        `Stylistics: ${"0"}`,
                        `Punctuation: ${"0"}`,
                        `Structure: ${"0"}`
                      ]
                    }
                  ]
                },
                {
                  stack: [
                    {
                      ul: [
                        `Readability (Flesch-Kincaid index): ${this.state.readabilityObjQ1 ? this.state.readabilityObjQ1.stats.fleschKincaid.readingEase : 0}`,
                        `Words: ${this.state.readabilityObjQ1 ? this.state.readabilityObjQ1.stats.counters.words : 0}`,
                        `Text length: ${this.state.readabilityObjQ1 ? this.state.readabilityObjQ1.stats.counters.length : 0}`,
                        `Without spaces: ${this.state.readabilityObjQ1 ? this.state.readabilityObjQ1.stats.counters.clearLength : 0}`,
                      ]
                    }
                  ]
                }
              ]
            ],
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 1) ? '#EEEEEE' : null;
              }
            }
          }
        },
        {
          columns: [
            [
              {
                text: 'IELTS Writing',
                fontSize: 18,
                color: "#1b0b7a",
                pageOrientation: 'portrait',
                pageBreak: 'before'
              },
              {
                text: `Test date: ${this.state.currentDate}`, margin: [0, 20, 0, 0]
              }
              ,
              {
                text: `Student name: ${JSON.parse(localStorage.getItem("user")).name}`
              }
            ],
            [
              {
                image: 'image1',
                width: 30,
                height: 30,
                style: 'image',
                pageOrientation: 'portrait',
                pageBreak: 'before'
              },
              {
                image: 'image2',
                width: 80,
                height: 80,
                style: 'image',
                margin: [0, 10, 0, 0]
              }
            ],
          ]
        },
        {
          text: "Task 2",
          bold: true
        },
        {
          text: `Question: ${this.state.fullTestObj.sections[0].questions[1].question}`,
          margin: [0, 5, 0, 0]
        },
        {
          text: this.state.fullTestObj.sections[0].questions[1].passage,
          margin: [0, 10, 0, 0],
          bold: true
        },
        {
          text: 'Answer',
          margin: [0, 10, 0, 0],
          bold: true
        },
        this.state.answers[1]?.length > 0 ? html.content[4] : { text: '' },
        html.content[5],
        {
          margin: [0, 5, 0, 15],
          table: {
            widths: [150, 250],
            body: [
              ['Possible Improvements', 'Metrics'],
              [
                {
                  stack: [
                    {
                      ul: [
                        `Grammar: ${this.state.grammarErrorCountQ2.grammar}`,
                        `Spelling: ${this.state.grammarErrorCountQ2.spelling}`,
                        `Punctuation: ${"0"}`,
                      ]
                    }
                  ]
                },
                {
                  stack: [
                    {
                      ul: [
                        `Words: ${this.state.readabilityObjQ2 ? this.state.readabilityObjQ2.stats.counters.words : 0}`,
                      ]
                    }
                  ]
                }
              ]
            ],
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#EEEEEE' : null;
              }
            }
          }
        },
      ],
      images: {
        image1: "https://i.ibb.co/sKFnZXp/U-Test-Logo-new-square.png",
        image2: "https://i.ibb.co/8jV816X/Band-Score-Table.png",
        image3: this.state.fullTestObj.sections[0].questions[0].passage
      },
      styles: {
        image: {
          alignment: 'right'
        }
      }
    };

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();

    this.handleCloseLoading();
  }

  handleDoneTestDialog = async (submitter, submitterUsername) => {
    try {
      this.startLoadingDialog();
      this.setState({
        isOpenLoadingDialog: true,
        statusText: "Creating a report...",
      });

      const resultObj = await this.props.handleCreateStudentReport();

      this.setState({
        isConfirmDone: false,
        reportCreated: true,
      });

      this.handleLoading();
      const q1 = await this.handleGrammarChecking(this.state.answers[0]);
      const q2 = await this.handleGrammarChecking(this.state.answers[1]);

      await this.setState({
        grammarErrorCountQ1: q1.grammarErrorCount,
        grammarErrorCountQ2: q2.grammarErrorCount,
        highlightedTextQ1: q1.highlightedText,
        highlightedTextQ2: q2.highlightedText,
        lastEditQ1: q1.lastEdit,
        lastEditQ2: q2.lastEdit,
        grammarCheckObjQ1: q1.grammarCheckObj,
        grammarCheckObjQ2: q2.grammarCheckObj,
        readabilityObjQ1: q1.readabilityObj,
        readabilityObjQ2: q2.readabilityObj,
      });

      // console.log({
      //   userObj: JSON.parse(localStorage.getItem("user")),
      //   currentDate: this.state.currentDate,
      //   fullTestObj: this.state.fullTestObj,
      //   answerQ1Obj:
      //     this.state.answers[0] === undefined ? "" : this.state.answers[0]
      //   ,
      //   answerQ2Obj:
      //     this.state.answers[1] === undefined ? "" : this.state.answers[1],

      //   grammarCheckObj:
      //     this.state.grammarCheckObj === undefined
      //       ? {}
      //       : this.state.grammarCheckObj,

      //   grammarErrorCountQ1: this.state.grammarErrorCountQ1,
      //   grammarErrorCountQ2: this.state.grammarErrorCountQ2,
      //   highlightedTextQ1: this.state.highlightedTextQ1,
      //   highlightedTextQ2: this.state.highlightedTextQ2,
      //   lastEditQ1: this.state.lastEditQ1,
      //   lastEditQ2: this.state.lastEditQ2,
      //   grammarCheckObjQ1: this.state.grammarCheckObjQ1,
      //   grammarCheckObjQ2: this.state.grammarCheckObjQ2,
      //   readabilityObjQ1: this.state.readabilityObjQ1,
      //   readabilityObjQ2: this.state.readabilityObjQ2,
      // });

      this.setState({
        readyToShow: true,
        grammarChecked: true,
      });

      //get table html
      const pdfTable = document.getElementById("divToPrint");
      //html to pdf format
      var html = htmlToPdfmake(pdfTable.innerHTML, {
        imagesByReference: true,
      });

      const documentDefinition = {
        content: [
          {
            columns: [
              [
                {
                  text: 'IELTS Writing',
                  fontSize: 18,
                  color: "#1b0b7a"
                },
                {
                  text: `Test date: ${this.state.currentDate}`, margin: [0, 20, 0, 0]
                }
                ,
                {
                  text: `Student name: ${JSON.parse(localStorage.getItem("user")).name}`
                }
              ],
              [
                {
                  image: 'image1',
                  width: 30,
                  height: 30,
                  style: 'image'
                },
                {
                  image: 'image2',
                  width: 80,
                  height: 80,
                  style: 'image',
                  margin: [0, 10, 0, 0]
                }
              ],
            ]
          },
          {
            text: "Task 1",
            bold: true
          },
          {
            text: `Question: ${this.state.fullTestObj.sections[0].questions[0].question}`,
            margin: [0, 5, 0, 0]
          },
          {
            image: 'image3',
            width: 250,
            margin: [0, 10, 0, 0],
            alignment: 'center'
          },
          {
            text: 'Answer',
            margin: [0, 10, 0, 0],
            bold: true
          },
          this.state.answers[0]?.length > 0 ? html.content[1] : { text: '' },
          html.content[2],
          {
            margin: [0, 15, 0, 15],
            table: {
              widths: [150, 250],
              body: [
                ['Possible Improvements', 'Metrics'],
                [
                  {
                    stack: [
                      {
                        ul: [
                          `Grammar: ${this.state.grammarErrorCountQ1.grammar}`,
                          `Spelling: ${this.state.grammarErrorCountQ1.spelling}`,
                          `Punctuation: ${"0"}`,
                        ]
                      }
                    ]
                  },
                  {
                    stack: [
                      {
                        ul: [
                          `Words: ${this.state.readabilityObjQ1 ? this.state.readabilityObjQ1.stats.counters.words : 0}`,
                        ]
                      }
                    ]
                  }
                ]
              ],
              layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 1) ? '#EEEEEE' : null;
                }
              }
            }
          },
          {
            columns: [
              [
                {
                  text: 'IELTS Writing',
                  fontSize: 18,
                  color: "#1b0b7a",
                  pageOrientation: 'portrait',
                  pageBreak: 'before'
                },
                {
                  text: `Test date: ${this.state.currentDate}`, margin: [0, 20, 0, 0]
                }
                ,
                {
                  text: `Student name: ${JSON.parse(localStorage.getItem("user")).name}`
                }
              ],
              [
                {
                  image: 'image1',
                  width: 30,
                  height: 30,
                  style: 'image',
                  pageOrientation: 'portrait',
                  pageBreak: 'before'
                },
                {
                  image: 'image2',
                  width: 80,
                  height: 80,
                  style: 'image',
                  margin: [0, 10, 0, 0]
                }
              ],
            ]
          },
          {
            text: "Task 2",
            bold: true
          },
          {
            text: `Question: ${this.state.fullTestObj.sections[0].questions[1].question}`,
            margin: [0, 5, 0, 0]
          },
          {
            text: this.state.fullTestObj.sections[0].questions[1].passage,
            margin: [0, 10, 0, 0],
            bold: true
          },
          {
            text: 'Answer',
            margin: [0, 10, 0, 0],
            bold: true
          },
          this.state.answers[1]?.length > 0 ? html.content[4] : { text: '' },
          html.content[5],
          {
            margin: [0, 5, 0, 15],
            table: {
              widths: [150, 250],
              body: [
                ['Possible Improvements', 'Metrics'],
                [
                  {
                    stack: [
                      {
                        ul: [
                          `Grammar: ${this.state.grammarErrorCountQ2.grammar}`,
                          `Spelling: ${this.state.grammarErrorCountQ2.spelling}`,
                          `Punctuation: ${"0"}`,
                        ]
                      }
                    ]
                  },
                  {
                    stack: [
                      {
                        ul: [
                          `Words: ${this.state.readabilityObjQ2 ? this.state.readabilityObjQ2.stats.counters.words : 0}`,
                        ]
                      }
                    ]
                  }
                ]
              ],
              layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#EEEEEE' : null;
                }
              }
            }
          },
        ],
        images: {
          image1: "https://i.ibb.co/sKFnZXp/U-Test-Logo-new-square.png",
          image2: "https://i.ibb.co/8jV816X/Band-Score-Table.png",
          image3: this.state.fullTestObj.sections[0].questions[0].passage
        },
        styles: {
          image: {
            alignment: 'right'
          }
        }
      };

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
      pdfDocGenerator.getBlob(async (blob) => {
        let formData = new FormData();
        await formData.append("test_id", this.state.test_id);
        await formData.append("result_id", resultObj.result_id);
        await formData.append("submitter", submitter);
        await formData.append("submitterUsername", submitterUsername);
        await formData.append("testCategory", "IELTS");
        await formData.append("testType", "Writing");
        await formData.append("assignTo", "Unassigned");
        await formData.append("tags", "IELTS");
        await formData.append("tags", "Writing");
        await formData.append("tags", "Full");
        await formData.append("isUpdate", "false");
        await formData.append("createdAt", Date.now());
        await formData.append(
          "submittedFile",
          blob,
          `${submitter}_${this.state.currentDateWithFormat}.pdf`
        );

        //const isSubmitted = await this.props.handleSubmitResult();

        this.setState({
          filePrepared: true,
        });

        await axios({
          method: "post",
          url: "https://chatter-funky-busby.glitch.me/tests/submit",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });

        //await this.props.handleEndTest();
        await this.setState({
          testSubmitted: true,
          resultObj: resultObj,
        });

        //this.handleCloseLoading();
      });
    } catch (err) {
      console.log(err);
      this.handleCloseLoading();
    }
  };

  onClickQuestionBox = (num) => {
    this.setState({
      focusedQuestion: num,
      currentQuestion: num,
    });

    this.props.keepCurrentSession(this.state);

    this.handleCloseLoading();
  }

  handleChangeQuestion = (type) => {
    if (type === "next") {
      if (this.state.currentQuestion === 2) {
        return;
      }
      this.setState({
        currentQuestion: this.state.currentQuestion + 1,
        focusedQuestion: this.state.currentQuestion + 1
      });
    } else if (type === "back") {
      if (this.state.currentQuestion === 0) {
        return;
      }

      this.setState({
        currentQuestion: this.state.currentQuestion - 1,
        focusedQuestion: this.state.currentQuestion - 1
      });
    }

    this.props.keepCurrentSession(this.state);
  };

  handleOpenLoadingDialog = () => {
    this.setState({
      isOpenLoadingDialog: true,
    });
  };

  handleCloseLoadingDialog = () => {
    this.setState({
      isOpenLoadingDialog: false,
    });
  };

  startLoadingDialog = () => {
    clearInterval(this.myInterval);
    this.myInterval = setInterval(() => {
      if (this.state.loadingPercentage < 20) {
        this.setState((prevState) => ({
          loadingPercentage: prevState.loadingPercentage + 1,
        }));
      }
      if (
        this.state.loadingPercentage < 50 &&
        this.state.loadingPercentage > 19 &&
        this.state.reportCreated
      ) {
        this.setState((prevState) => ({
          loadingPercentage: prevState.loadingPercentage + 1,
          statusText: "Grammar checking...",
        }));
      }
      if (
        this.state.loadingPercentage < 75 &&
        this.state.loadingPercentage > 49 &&
        this.state.grammarChecked
      ) {
        this.setState((prevState) => ({
          loadingPercentage: prevState.loadingPercentage + 1,
          statusText: "Preparing files...",
        }));
      }
      if (
        this.state.loadingPercentage < 99 &&
        this.state.loadingPercentage > 74 &&
        this.state.filePrepared
      ) {
        this.setState((prevState) => ({
          loadingPercentage: prevState.loadingPercentage + 1,
          statusText: "Submitting...",
        }));
      }
      if (this.state.loadingPercentage === 99 && this.state.testSubmitted) {
        this.setState({
          loadingPercentage: 100,
          statusText: "Submitted...",
        });

        CreateNewNotification(
          JSON.parse(localStorage.getItem("user")).username,
          "full_report",
          {
            result_id: this.state.resultObj.result_id,
            message: "Your full report is ready! Click here to check it out!",
          }
        );

        localStorage.setItem('readingSession', null);
        localStorage.setItem('listeningSession', null);
        localStorage.setItem('writingSession', null);
        localStorage.setItem('testSession', null);

        this.props.history.push(
          `/test/evaluation?test-type=IELTS Full Test&test-id=${this.props.purchasedTestId}&report-id=${this.state.resultObj.result_id}`
        );
      }
    }, 100);
  };

  render() {
    const { classes } = this.props;

    const timesUp = (
      <Dialog
        open={true}
        onClose={this.handleCloseConfirmDoneTestDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Time's up!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please click on{" "}
            <Box fontWeight="fontWeightBold" display="inline">
              Confirm
            </Box>
            , to check your test score.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              /*this.handleDoneTestDialog(
                this.props.user.name,
                this.props.user.username
              );*/this.handleDoneTestDialog(
              this.props.user.name,
              this.props.user.username
            );
            }}
            style={{
              backgroundColor: "#25333e",
              color: "white",
              textTransform: "none",
            }}
            autoFocus
          >
            Confirm
          </Button>
          {/*<Button onClick={this.handleDoneTestDialog} color="primary" autoFocus>
                        Confirm
                    </Button>*/}
        </DialogActions>
      </Dialog>
    );

    return (
      <ThemeProvider theme={theme}>
        <LoadingDialog
          open={this.state.isOpenLoadingDialog}
          percentage={this.state.loadingPercentage}
          statusText={this.state.statusText}
        />
        <Grid
          container
          style={{
            maxHeight: this.state.window.height,
            padding: "20px",
            zoom: "100%",
          }}
        >
          <Grid item xs={12} style={{ display: "none" }}>
            <WritingReport
              userObj={JSON.parse(localStorage.getItem("user"))}
              currentDate={this.state.currentDate}
              fullTestObj={this.state.fullTestObj}
              answerQ1Obj={
                this.state.answers[0] === undefined ? "" : this.state.answers[0]
              }
              answerQ2Obj={
                this.state.answers[1] === undefined ? "" : this.state.answers[1]
              }
              grammarCheckObj={
                this.state.grammarCheckObj === undefined
                  ? {}
                  : this.state.grammarCheckObj
              }
              grammarErrorCountQ1={this.state.grammarErrorCountQ1}
              grammarErrorCountQ2={this.state.grammarErrorCountQ2}
              highlightedTextQ1={this.state.highlightedTextQ1}
              highlightedTextQ2={this.state.highlightedTextQ2}
              lastEditQ1={this.state.lastEditQ1}
              lastEditQ2={this.state.lastEditQ2}
              grammarCheckObjQ1={this.state.grammarCheckObjQ1}
              grammarCheckObjQ2={this.state.grammarCheckObjQ2}
              readabilityObjQ1={this.state.readabilityObjQ1}
              readabilityObjQ2={this.state.readabilityObjQ2}
            />
          </Grid>
          {this.state.isCheckUserInfo && (
            <UserInfoPage
              user={this.props.user}
              handleUserInfoChecked={this.handleUserInfoChecked}
            />
          )}
          {this.state.isReadInstruction && (
            <InstructionsPage
              user={this.props.user}
              handleDoneReadInstruction={this.handleDoneReadInstruction}
            />
          )}
          {!this.state.isDone && this.state.isStartTest && (
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: "15px" }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="body2" align="center">
                      <AccountBoxRoundedIcon /> {this.props.user.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: "bold",
                        color:
                          (this.state.timer >= 590 &&
                            this.state.timer <= 600) ||
                            (this.state.timer >= 290 && this.state.timer <= 300)
                            ? "#c91e24"
                            : "",
                      }}
                    >
                      <AccessAlarmRoundedIcon
                        style={{
                          color:
                            (this.state.timer >= 590 &&
                              this.state.timer <= 600) ||
                              (this.state.timer >= 290 && this.state.timer <= 300)
                              ? "#c91e24"
                              : "",
                        }}
                      />{" "}
                      {this.handleAddLeadingZero(
                        Math.floor(this.state.timer / 3600)
                      )}{" "}
                      :{" "}
                      {this.state.timer === 3600
                        ? "00"
                        : this.handleAddLeadingZero(
                          Math.floor(this.state.timer / 60)
                        )}{" "}
                      : {this.handleAddLeadingZero(this.state.timer % 60)}{" "}
                      minutes left
                    </Typography>
                    {this.state.timer === 0 && timesUp}
                  </Grid>
                </Grid>
              </Grid>
              {this.state.currentTestObject !== undefined && (
                <Grid item xs={12}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: "10px",
                      backgroundColor: "#25333e",
                      color: "white",
                      padding: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ textAlign: "justify" }}
                      gutterBottom
                      align="left"
                    >
                      <Box fontWeight="fontWeightBold" display="inline">
                        Part{" "}
                        {
                          this.state.currentTestObject.sections[0].questions[
                            this.state.currentQuestion - 1
                          ].no
                        }
                      </Box>
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ textAlign: "justify" }}
                      gutterBottom
                      align="left"
                    >
                      You should spend about{" "}
                      {this.state.currentTestObject.sections[0].questions[
                        this.state.currentQuestion - 1
                      ].no === "1"
                        ? "20"
                        : "40"}{" "}
                      minutes on this task. Write at least{" "}
                      {this.state.currentTestObject.sections[0].questions[
                        this.state.currentQuestion - 1
                      ].no === "1"
                        ? "150"
                        : "250"}{" "}
                      words <span style={{ fontSize: 6, color: "#DDDDDD" }}> {btoa(this.props.user.username)}</span>
                    </Typography>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12}>
                {this.state.currentTestObject !== undefined && (
                  <Grid container>
                    <Grid
                      item
                      align="left"
                      xs={6}
                      style={{
                        height: this.state.window.height - 250,
                        maxHeight: this.state.window.height - 250,
                      }}
                    >
                      <Paper
                        variant="outlined"
                        style={{
                          height: this.state.window.height - 280,
                          backgroundImage: `url("/U-Test-Logo-new-bw.png")`,
                          backgroundSize: "120px",
                          backgroundRepeat: "space",
                        }}
                      >
                        <Grid item align='right' xs={12} style={{ padding: '10px' }}>
                          <ButtonGroup size="small" variant="contained" aria-label="contained primary button group">
                            <Button onClick={() => { this.handleFontSize('plus') }} style={{ color: "white", backgroundColor: "#42647d" }}>
                              <AddRoundedIcon />
                            </Button>
                            <Button onClick={() => { this.handleFontSize('minus') }} style={{ color: "white", backgroundColor: "#42647d" }}>
                              <RemoveRoundedIcon />
                            </Button>
                            <Button onClick={this.handleRestTextSize} style={{ color: "white", backgroundColor: "#42647d" }}>
                              Reset
                              </Button>
                          </ButtonGroup>
                        </Grid>
                        <Grid
                          style={{
                            padding: "20px",
                            overflow: "auto",
                            maxHeight: this.state.window.height - 260,
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{ textAlign: "justify" }}
                            gutterBottom
                          >
                            <Box fontWeight="fontWeightBold" display="inline">
                              {this.state.testObject !== null &&
                                this.state.testObject.parts[0].title}
                            </Box>
                          </Typography>
                          {this.state.currentTestObject.sections[0].questions[
                            this.state.currentQuestion - 1
                          ].passage.includes("https://") &&
                            this.state.currentTestObject.sections[0].questions[
                              this.state.currentQuestion - 1
                            ].question
                              .split("\\n")
                              .map((text) => (
                                <Typography
                                  style={{
                                    textAlign: "justify",
                                    fontWeight: "bold",
                                    fontSize: `${this.state.fontSize}rem`
                                  }}
                                >
                                  {text}
                                </Typography>
                              ))}
                          {this.state.currentTestObject.sections[0].questions[
                            this.state.currentQuestion - 1
                          ].passage.includes("https://") ? (
                              <Box
                                component="img"
                                style={{ width: "100%", marginBottom: "10px" }}
                                alt="The house from the offer."
                                src={
                                  this.state.currentTestObject.sections[0]
                                    .questions[this.state.currentQuestion - 1]
                                    .passage
                                }
                              />
                            ) : (
                              <Typography
                                style={{
                                  textAlign: "justify",
                                  marginBottom: "50px",
                                  fontSize: `${this.state.fontSize}rem`
                                }}
                              >
                                {this.state.currentTestObject.sections[0].questions[
                                  this.state.currentQuestion - 1
                                ].passage
                                  .split("\n")
                                  .map((text) => (
                                    <Box
                                      fontWeight="fontWeightBold"
                                      display="inline"
                                    >
                                      {text + " "}
                                    </Box>
                                  ))}
                              </Typography>
                            )}
                          {!this.state.currentTestObject.sections[0].questions[
                            this.state.currentQuestion - 1
                          ].passage.includes("https://") &&
                            this.state.currentTestObject.sections[0].questions[
                              this.state.currentQuestion - 1
                            ].question
                              .split("\\n")
                              .map((text) => (
                                <Typography
                                  style={{
                                    textAlign: "justify",
                                    fontWeight: "bold",
                                    fontSize: `${this.state.fontSize}rem`
                                  }}
                                >
                                  {text}
                                </Typography>
                              ))}
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        height: this.state.window.height - 250,
                        maxHeight: this.state.window.height - 250,
                      }}
                    >
                      <Paper
                        variant="outlined"
                        style={{
                          height: this.state.window.height - 280,
                          overflow: "auto",
                        }}
                      >
                        <Grid container>
                          <Grid item align='right' xs={12} style={{ marginBottom: '10px', marginTop: '10px', paddingRight: '5px' }}>
                            <Grid container justify='flex-end' alignItems="center">
                              <Grid item style={{ paddingRight: '5px' }}>
                                <Typography variant="h5" style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                  Text size:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <ButtonGroup size="small" variant="contained" aria-label="contained primary button group">
                                  <Button onClick={() => { this.onClickFontSize('sm') }} style={{ color: "white", backgroundColor: "#42647d" }}>
                                    Small
                                  </Button>
                                  <Button onClick={() => { this.onClickFontSize('md') }} style={{ color: "white", backgroundColor: "#42647d" }}>
                                    Medium
                                  </Button>
                                  <Button onClick={() => { this.onClickFontSize('lg') }} style={{ color: "white", backgroundColor: "#42647d" }}>
                                    Large
                                  </Button>
                                </ButtonGroup>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            {/* =================================== SECTIONS =================================== */}
                            <textarea
                              name={this.state.currentQuestion - 1}
                              className="form-control"
                              data-gramm="false"
                              data-gramm_editor="false"
                              data-enable-grammarly="false"
                              spellcheck="false"
                              style={{
                                fontSize: this.state.textAreaSize[`${this.state.fontSizeMap}`],
                                width: "100%",
                                marginTop: "20px",
                                height: this.state.window.height - 390,
                              }}
                              onChange={this.handleChange}
                              onClick={() => this.onClickQuestionBox(this.state.currentQuestion)}
                              value={
                                this.state.answers[
                                  this.state.currentQuestion - 1
                                ]
                                  ? this.state.answers[
                                  this.state.currentQuestion - 1
                                  ]
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            align="left"
                            xs={12}
                            style={{ paddingLeft: "20px" }}
                          >
                            <Typography style={{ fontSize: '1rem' }}>
                              Word count:{" "}
                              {this.state.answers[
                                this.state.currentQuestion - 1
                              ] === undefined ||
                                this.state.answers[this.state.currentQuestion - 1]
                                  .length === 0
                                ? 0
                                : this.state.answers[
                                  this.state.currentQuestion - 1
                                ].split(" ").length -
                                1 +
                                this.state.answers[
                                  this.state.currentQuestion - 1
                                ].split("\n").length -
                                1 +
                                1}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Typography
                        align="right"
                        variant="subtitle2"
                        style={{ color: "#c8c8c8", fontWeight: "bold" }}
                      >
                        {btoa(this.props.user.username)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  zIndex: 1200,
                  position: "absolute",
                  bottom: 0,
                  width: "95%",
                }}
              >
                <Grid container>
                  <Grid item xs={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.reviewedQuestion[this.state.focusedQuestion] ? this.state.reviewedQuestion[this.state.focusedQuestion] : false}
                          onChange={this.handleReviewQuestion}
                          name="reviewed"
                          color="primary"
                        />
                      }
                      label="Review"
                    />
                  </Grid>
                  <Grid item xs={10} align="left">
                    <Paper variant="outlined">
                      <Button
                        size="small"
                        variant={
                          this.state.answers[0] === undefined ||
                            this.state.answers[0]?.length === 0
                            ? "outlined"
                            : "contained"
                        }
                        onClick={() => { this.onClickQuestionBox(1) }}
                        color="primary"
                        style={{
                          maxWidth: "20px",
                          maxHeight: "20px",
                          minWidth: "20px",
                          minHeight: "20px",
                          margin: "0.5%",
                          color:
                            this.state.answers[0] === undefined ||
                              this.state.answers[0] === null ||
                              this.state.answers[0]?.length === 0
                              ? "#25333e"
                              : "",
                          backgroundColor:
                            this.state.answers[0] === undefined ||
                              this.state.answers[0] === null ||
                              this.state.answers[0]?.length === 0
                              ? (this.state.focusedQuestion === 1 ? "#dbecf6" : "")
                              : "#25333e",
                          borderColor:
                            this.state.answers[0] === undefined ||
                              this.state.answers[0] === null ||
                              this.state.answers[0]?.length === 0
                              ? "#25333e"
                              : "",
                          borderRadius: this.state.reviewedQuestion[1] ? 75 : 3,
                        }}
                      >
                        1
                      </Button>
                      <Button
                        size="small"
                        variant={
                          this.state.answers[1] === undefined ||
                            this.state.answers[1]?.length === 0
                            ? "outlined"
                            : "contained"
                        }
                        color="primary"
                        onClick={() => { this.onClickQuestionBox(2) }}
                        style={{
                          maxWidth: "20px",
                          maxHeight: "20px",
                          minWidth: "20px",
                          minHeight: "20px",
                          margin: "0.5%",
                          color:
                            this.state.answers[1] === undefined ||
                              this.state.answers[1] === null ||
                              this.state.answers[1]?.length === 0
                              ? "#25333e"
                              : "",
                          backgroundColor:
                            this.state.answers[1] === undefined ||
                              this.state.answers[1] === null ||
                              this.state.answers[1]?.length === 0
                              ? (this.state.focusedQuestion === 2 ? "#dbecf6" : "")
                              : "#25333e",
                          borderColor:
                            this.state.answers[1] === undefined ||
                              this.state.answers[1] === null ||
                              this.state.answers[1]?.length === 0
                              ? "#25333e"
                              : "",
                          borderRadius: this.state.reviewedQuestion[2] ? 75 : 3,
                        }}
                      >
                        2
                      </Button>
                    </Paper>
                  </Grid>
                  <Grid item xs={1} align="right">
                    <Grid container>
                      <Grid item xs={6}>
                        {!(this.state.currentQuestion === 1) && (
                          <IconButton
                            aria-label="add to favorites"
                            onClick={() => {
                              this.handleChangeQuestion("back");
                            }}
                          >
                            <ArrowBackRoundedIcon />
                          </IconButton>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          marginTop: !(this.state.currentQuestion === 2)
                            ? "0%"
                            : "5%",
                        }}
                      >
                        {!(this.state.currentQuestion === 2) ? (
                          <IconButton
                            aria-label="add to favorites"
                            onClick={() => {
                              this.handleChangeQuestion("next");
                            }}
                          >
                            <ArrowForwardRoundedIcon />
                          </IconButton>
                        ) : (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={this.handleConfirmDoneTestDialog}
                              style={{
                                backgroundColor: "#25333e",
                                color: "white",
                              }}
                            >
                              Done
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {this.state.isDone && (
            <Grid item xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                align="left"
                className={classes.testAlignLeft}
              >
                <Box fontWeight="fontWeightBold" display="inline">
                  Test is Done!
                </Box>
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                align="left"
                className={classes.testAlignLeft}
              >
                Back to home
              </Typography>
              <Link
                to="/"
                color="inherit"
                underline="none"
                style={{ textDecoration: "none", color: "#565656" }}
              >
                <Button
                  variant="contained"
                  onClick={this.props.handleEndTest}
                  style={{ backgroundColor: "#76323F", color: "white" }}
                >
                  <Box fontWeight="fontWeightBold" display="inline">
                    Home
                  </Box>
                </Button>
              </Link>
              <Typography
                variant="h5"
                gutterBottom
                align="left"
                className={classes.testAlignLeft}
              >
                See my report
              </Typography>
              <Link
                to="/"
                color="inherit"
                underline="none"
                style={{ textDecoration: "none", color: "#565656" }}
              >
                <Button
                  variant="contained"
                  onClick={this.props.handleEndTest}
                  style={{ backgroundColor: "#76323F", color: "white" }}
                >
                  <Box fontWeight="fontWeightBold" display="inline">
                    My report
                  </Box>
                </Button>
              </Link>
            </Grid>
          )}

          <PageBackdrop isLoading={this.state.isLoading} />

          <Dialog
            open={this.state.isConfirmDone}
            onClose={this.handleCloseConfirmDoneTestDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {this.state.isNotAllQuestion
                ? "There's still some time remaining."
                : "There's still some time remaining."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure that you want to submit the test? Please click{" "}
                <Box fontWeight="fontWeightBold" display="inline">
                  Confirm
                </Box>
                .
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseConfirmDoneTestDialog}
                variant="contained"
                style={{
                  backgroundColor: "#a6a6a6",
                  color: "white",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  /*this.handleDoneTestDialog(
                    this.props.user.name,
                    this.props.user.username
                  );*/this.handleDoneTestDialog(
                  this.props.user.name,
                  this.props.user.username
                );
                }}
                variant="contained"
                style={{
                  backgroundColor: "#25333e",
                  color: "white",
                  textTransform: "none",
                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {/*<Dialog
                        open={this.state.isConfirmDone}
                        onClose={this.handleCloseConfirmDoneTestDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Please confirm that There's still some time remaining."}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                If you select <Box fontWeight="fontWeightBold" display='inline'>Confirm</Box>, you will not be able to return to the test.
                                Are you sure you would like to finish this test?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseConfirmDoneTestDialog} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => { this.handleDoneTestDialog(this.props.user.name, this.props.user.username) }} color="primary" autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>*/}
        </Grid>
      </ThemeProvider>
    );
  }
}

export default compose(withStyles(useStyles))(TestWriting);
