//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//import components

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    paperRoot: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
    paperItem: {
        padding: theme.spacing(2),
        textAlign: 'left',
        height: '810px',
        maxHeight: '810px',
        overflow: 'auto',
    },
    testAlignLeft: {
        padding: theme.spacing(2),
    },
    gridContainerRoot: {
        marginTop: 80
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    marginLeft: {
        marginLeft: 20
    },
    marginTop: {
        marginTop: 20
    },
    margin: {
        margin: 5
    }
});

class TrueFalseNG extends Component {
    constructor() {
        super();

        this.state = {
            answerObj: {
                answers: {}
            },
            sizeMapHeader: {
                'sm': 'body2',
                'md': 'body1',
                'lg': 'h6',
            },
            sizeMapBody: {
                'sm': 'subtitle2',
                'md': 'body2',
                'lg': 'body1',
            },
            sizeMapCaption: {
                'sm': '0.7rem',
                'md': '0.8rem',
                'lg': '1rem',
            }
        };
    }

    componentDidMount = async () => {
        const filteredAnsObj = await this.props.answerObj?.parts[this.props.currentPart - 1].sections.find((element) => {
            return element.section_id === this.props.sectionInfo.section_id;
        })

        this.setState({
            answerObj: {
                section_id: this.props.sectionInfo.section_id,
                question_type: this.props.sectionInfo.question_type,
                answers: filteredAnsObj.answers.length === 0 ? {} : filteredAnsObj.answers
            }
        })
        console.log(filteredAnsObj === undefined ? '' : this.state.answerObj.answers.hasOwnProperty(Number(1)))
    }

    handleAnswerQuestion = (event, questionNo) => {
        const questionKey = '' + questionNo //Convert integer to string
        this.state.answerObj.answers[questionKey] = event.target.value;
        this.props.handleAnswerObj(this.state.answerObj)
        this.props.handleAnsweredQuestionNumber(questionNo);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ marginBottom: '20px' }}>
                <Grid item align='left' xs={12}>
                    <Typography variant={this.state.sizeMapHeader[`${this.props.fontSizeMap}`]} gutterBottom>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.section}</Box>
                    </Typography>
                </Grid>
                <Grid item align='left' xs={12}>
                    {this.props.sectionInfo.questions.map(q =>
                        <Accordion onFocus={() => {this.props.onFocusQuestion(Number(q.no))}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant={this.state.sizeMapBody[`${this.props.fontSizeMap}`]}>
                                    <Box fontWeight="fontWeightBold" display='inline' m={1}>{q.no}</Box> {q.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.marginLeft}>
                                <RadioGroup
                                    aria-label="q1"
                                    name={this.props.sectionInfo.section_id + '_' + q.no}
                                    value={this.state.answerObj.answers?.hasOwnProperty(Number(q.no)) ? this.state.answerObj.answers[Number(q.no)] + '' : ''}
                                    onChange={(event) => { this.handleAnswerQuestion(event, q.no) }}
                                    placeholder={this.props.sectionInfo.section_id}
                                >
                                    <FormControlLabel
                                        value="TRUE"
                                        control={<Radio color="default" />}
                                        label={<Typography style={{ fontSize: this.state.sizeMapCaption[`${this.props.fontSizeMap}`] }} gutterBottom>TRUE</Typography>}
                                    />
                                    <FormControlLabel
                                        value="FALSE"
                                        control={<Radio color="default" />}
                                        label={<Typography style={{ fontSize: this.state.sizeMapCaption[`${this.props.fontSizeMap}`] }} gutterBottom>FALSE</Typography>}
                                    />
                                    <FormControlLabel
                                        value="NG"
                                        control={<Radio color="default" />}
                                        label={<Typography style={{ fontSize: this.state.sizeMapCaption[`${this.props.fontSizeMap}`] }} gutterBottom>NOT GIVEN</Typography>}
                                    />
                                </RadioGroup>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Grid>
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(TrueFalseNG);