import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import React, { Component } from "react";
import { compose } from "recompose";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Hidden from '@material-ui/core/Hidden';

import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';

//import other library
import "react-calendar/dist/Calendar.css";
import { Link } from "react-router-dom";

//import components
import TestSchedulerPage from "../Scheduler/TestSchedulerPage";
import PageBackdrop from "../main/PageBackdrop";
import PayPal from "../payment/PayPal";
import CheckoutCreditCard from "../payment/omise-prebuilt-form/CheckoutCreditCard";
import PaymentSuccessfulPage from "./PaymentSuccessfulPage";

//HTML
import * as purchasePopup from './purchasePopup';
import * as termsAndAgreements from './termsAndAgreements';

const axios = require("axios").default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "80%",
  },
  rootContainer: {
    maxWidth: "75%",
  },
  rootCard: {
    maxWidth: "75%",
    width: "75%",
    margin: 10,
  },
  gridContainer: {
    maxWidth: "90%",
  },
  margin: {
    margin: "50px",
  },
  marginTop: {
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  header: {
    maxWidth: "80%",
  },
  body: {
    maxWidth: "80%",
    marginTop: 30,
  },
  divider: {
    maxWidth: "100%",
  },
  image: {
    margin: "auto",
    display: "block",
    margin: 10,
  },
  commentTextField: {
    width: "100%",
    maxWidth: "100%",
  },
  commentGridItem: {
    maxWidth: "80%",
    marginTop: 30,
    marginBottom: 100,
  },
});

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Roboto'
    ].join(','),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class ConfirmPaymentPage extends Component {
  constructor() {
    super();
    this.state = {
      finalAmount: 0,
      discount: 0,
      activeStep: 0,
      code: "",
      isInvalidCode: false,
      isApplyCode: false,
      isError: false,
      isPaid: false,
      isLoading: false,
      isScheduleSpeaking: false,
      isOpenAgreement: false,
      isAgreed: false,
      isOpenSnackBar: false,
      isPopupBlocked: false,
    };
  }

  componentDidMount = () => {
    document.body.style.backgroundColor = "#efefef";
    document.querySelector("body").scrollTo(0, 0);
    const query = new URLSearchParams(this.props.location.search);

    this.setState({
      originalAmount: query.get("amount"),
      finalAmount: query.get("amount"),
      courseName: query.get("course_name"),
      description: query.get("description"),
      test_id: query.get("test_id") ? undefined : query.get("test_id"),
    });
    this.checkPopupEnable();
  };

  checkPopupEnable = () => {
    // Attempt to open a small window
    const testPopup = window.open('', '', 'width=100,height=100');

    if (!testPopup || testPopup.closed || typeof testPopup.closed === 'undefined') {
      // Popup is blocked
      console.log('Popup blocked');

      this.setState({
        isPopupBlocked: true,
      })
      return;
    }

    console.log('Popup not blocked');

    // If the popup opens, close it
    testPopup.close();

    this.setState({
      isPopupBlocked: false,
    })
    return false;
  }

  handleApplyCouponCode = async (code) => {
    if (code.length === 0) {
      return;
    }

    try {
      this.handleOpenLoading();

      const res = await axios({
        method: "get",
        url:
          "https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/discounts/" +
          code.toUpperCase(),
      });

      const resData = res.data;

      if (resData.useCount !== undefined) {
        if (resData.useCount === 0) {
          this.setState({
            isInvalidCode: true,

          });

          this.handleCloseLoading();
          return;
        }
      }

      //let discount = this.state.finalAmount * 0.25;
      let finalAmount = this.state.finalAmount - Number(resData.discount) * 100;
      this.setState({
        finalAmount: finalAmount,
        discount: Number(resData.discount) * 100,
        isInvalidCode: false,
        isApplyCode: true,
        codeUsed: code.toUpperCase(),
      });

      this.handleCloseLoading();
      return;
    } catch (err) {
      console.log(err);
      this.setState({
        isInvalidCode: true,
        isApplyCode: false,
      });
      this.handleCloseLoading();
    }
  };

  handleDiscountUsed = async () => {
    try {
      const res = await axios({
        method: "post",
        url: `https://erratic-noiseless-wasabi.glitch.me/utestavenue-system-api/api/v1/discountsUsage/${this.state.codeUsed.toUpperCase()}/used`,
        data: {
          usedBy: JSON.parse(localStorage.getItem("user")).username,
        },
      });

      if (this.state.courseName !== "FULL IELTS") {
        this.setState({
          isPaid: true,
        });
      }

      return;
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = (event) => {
    const name = event.target.name;

    if (name === "code") {
      let value = event.target.value.toUpperCase().slice(0, 20);

      this.setState({
        ...this.state,
        [name]: value,
      });
      return;
    }

    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  //============================= BEGIN PAY BY CREDIT/DEBIT CARD ================================
  createCreditCardCharge = async (email, name, amount, token) => {
    try {
      this.handleOpenLoading();
      await this.setState({
        email: email,
        name: name,
      });

      const res = await axios({
        method: "post",
        url: "https://almond-magnetic-sale.glitch.me/checkout-credit-card",
        data: {
          email,
          name,
          amount,
          token,
          url: window.location.host
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const resData = res.data;
      if (resData.status === "successful") {
        await this.setState({
          email: email,
          name: name,
          amount: resData.amount / 100,
          omiseToken: resData.id,
          transaction: resData.transaction,
        });

        this.setState({
          charge: resData,
        });

        this.handleCoursePurchased();
        this.handleCloseLoading();
      } else if (resData.status === "pending") {
        window.open(resData.authorize_uri, '_blank', "noreferrer");

        var refreshIntervalId = setInterval(async () => {
          let count = 0;
          let isPaid = false;

          if (count !== 10 && !isPaid) {
            const res = await axios({
              method: "get",
              url: `https://almond-magnetic-sale.glitch.me/charges/id/${resData.id}`
            });

            console.log(res);

            if (res.data.status === "successful" && !isPaid) {
              await this.setState({
                email: email,
                name: name,
                amount: res.data.amount / 100,
                omiseToken: res.data.id,
                transaction: res.data.transaction,
              });

              this.setState({
                charge: res.data,
              });

              this.handleCoursePurchased();
              isPaid = true;
              this.handleCloseLoading();
              clearInterval(refreshIntervalId);
            } else if (res.data.status === "failed") {
              this.setState({
                isError: true,
              });
      
              this.handleCloseLoading();
              return;
            } else {
              count = count + 1;
            }
          }
        }, 5000);
      } else if (resData.status === "failed") {
        this.setState({
          isError: true,
        });

        this.handleCloseLoading();
        return;
      }
    } catch (err) {
      console.log(err);
      this.setState({
        isError: true,
      });
      this.handleCloseLoading();
    }
  };

  handleCoursePurchased = async () => {
    try {
      let res;
      try {
        res = await axios({
          method: "post",
          url: `${api_base_url}/api/test/test-purchase-status?user_name=${JSON.parse(localStorage.getItem("user")).username}&test_type=${this.state.courseName}`,
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
          },
        });
      } catch (err) {
        this.setState({
          isOpenSnackBar: true
        });

        console.log(err);

        return;
      }

      const resData = res.data;

      await axios({
        method: "post",
        url: "https://almond-magnetic-sale.glitch.me/charges/create",
        data: {
          purchasedId: resData._id,
          purchasedUsername: resData.username,
          purchasedChargeAmount: this.state.amount,
          purchasedChargeId: this.state.omiseToken,
          purchasedChargeTransaction: this.state.transaction,
        },
      });

      this.setState({
        purchasedId: resData._id,
      });

      if (this.state.isApplyCode) {
        this.handleDiscountUsed();
      }

      if (this.state.courseName === "FULL IELTS") {
        this.setState({
          isScheduleSpeaking: true,
        });
      } else {
        this.handleEndScheduleSpeaking()
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleEndScheduleSpeaking = () => {
    this.setState({
      isPaid: true,
      isScheduleSpeaking: false,
    });
  };

  handleFreePaid = async (email, name) => {
    this.handleOpenLoading();

    await this.setState({
      email: email,
      name: name,
      amount: 0,
      omiseToken: "-",
      transaction: "-",
    });

    await this.handleCoursePurchased();

    this.handleCloseLoading();
  };
  //============================= END PAY BY CREDIT/DEBIT CARD ================================

  handleCloseSnackBar = () => {
    this.setState({
      isError: false,
    });
  };

  handleCloseLoading = () => {
    this.setState({
      isLoading: false,
    });
  };

  handleOpenLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  //=========== AGREEMENT ==================

  handleCloseAgreement = () => {
    this.setState({
      isOpenAgreement: false,
    });
  };

  handleOpenAgreement = () => {
    this.setState({
      isOpenAgreement: true,
    });
  };

  handleConfirmAgreement = () => {
    this.handleCloseAgreement();
    this.setState({
      isAgreed: true,
    });
  };

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1
    });
  };

  handleBack = () => {
    if (this.state.activeStep > 0) {
      this.setState({
        activeStep: this.state.activeStep - 1
      });
    }
  };

  handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      isOpenSnackBar: false
    });
  }

  //=========== AGREEMENT ==================

  render() {
    const { classes } = this.props;
    const mobileUI = (
      <Grid item xs={12} style={{ maxWidth: window.innerWidth }}>
        <Grid
          container
          spacing={5}
          justify="center"
          style={{
            marginTop: "50px",
            marginBottom: "10%",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <PageBackdrop isLoading={this.state.isLoading} />
          <Dialog
            open={this.state.isOpenAgreement}
            onClose={this.handleCloseAgreement}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"AGREEMENT & COPYRIGHT ACKNOWLEDGEMENT"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText ref={this.myRef} id="alert-dialog-description">
                <div dangerouslySetInnerHTML={{ __html: this.state.activeStep === 0 ? termsAndAgreements.termsAndAgreement : purchasePopup.purchasePopup }} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                  <MobileStepper
                    variant="dots"
                    steps={2}
                    position="static"
                    activeStep={this.state.activeStep}
                    nextButton={
                      <Button size="small" onClick={this.handleNext} disabled={this.state.activeStep === 1}>
                        Next
                        <KeyboardArrowRight />
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                        <KeyboardArrowLeft />
                        Back
                      </Button>
                    }
                  />
                </Grid>
                <Grid item xs={12} align="right">
                  <Button onClick={this.handleCloseAgreement} style={{ color: "grey", marginRight: "10px" }}>
                    Disagree
                  </Button>
                  <Button
                    disableElevation
                    onClick={this.handleConfirmAgreement}
                    variant="contained"
                    style={{ backgroundColor: "#76323F", color: "white" }}
                    autoFocus
                  >
                    Agree
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>

          {!this.state.isPaid && !this.state.isScheduleSpeaking && (
            <Snackbar
              open={this.state.isError}
              autoHideDuration={6000}
              onClose={this.handleCloseSnackBar}
            >
              <Alert onClose={this.handleCloseSnackBar} severity="error">
                Payment was unsuccessful. Your credit card was not charged.
              </Alert>
            </Snackbar>
          )}
          {!this.state.isPaid && !this.state.isScheduleSpeaking && (
            <Grid item align="left" xs={12}>
              <Typography variant="h4" style={{ fontWeight: "bold" }} gutterBottom>
                Payment method
              </Typography>
            </Grid>
          )}
          {!this.state.isPaid && !this.state.isScheduleSpeaking && (
            <Grid item align="right" xs={12} sm={4}>
              <Card style={{ padding: "30px" }}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Make a payment
                  </Typography>
                  <Typography color="textSecondary">Powered by Omise</Typography>
                  <Grid container justify="center">
                    <Grid item align="center" xs={12}>
                      <Grid container justify="center">
                        <Grid item align="left" xs={6}>
                          <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                            Name:
                          </Typography>
                        </Grid>
                        <Grid item align="right" xs={6}>
                          <Typography
                            variant="body1"
                            color="primary"
                            gutterBottom
                            style={{ color: "#76323F", fontWeight: "bold" }}
                          >
                            {this.state.courseName}
                          </Typography>
                        </Grid>
                        <Grid item align="left" xs={6}>
                          <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                            Description:
                          </Typography>
                        </Grid>
                        <Grid item align="right" xs={6}>
                          <Typography variant="body1" gutterBottom>
                            {this.state.description}
                          </Typography>
                        </Grid>
                        <Grid item align="left" xs={6}>
                          <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                            Amount:
                          </Typography>
                        </Grid>
                        <Grid item align="right" xs={6}>
                          <Typography variant="body1" gutterBottom>
                            {this.state.originalAmount / 100} THB
                          </Typography>
                        </Grid>
                        {this.state.isApplyCode && (
                          <Grid item align="left" xs={6}>
                            <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                              Discount:
                            </Typography>
                          </Grid>
                        )}
                        {this.state.isApplyCode && (
                          <Grid item align="right" xs={6}>
                            <Typography
                              variant="body1"
                              style={{ color: "#80c904" }}
                              gutterBottom
                            >
                              -{" "}
                              {this.state.discount > this.state.originalAmount
                                ? this.state.originalAmount / 100
                                : this.state.discount / 100}{" "}
                              THB
                            </Typography>
                          </Grid>
                        )}
                        {this.state.isApplyCode && (
                          <Grid item align="left" xs={6}>
                            <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                              Total amount:
                            </Typography>
                          </Grid>
                        )}
                        {this.state.isApplyCode && (
                          <Grid item align="right" xs={6}>
                            <Typography variant="body1" gutterBottom>
                              {this.state.finalAmount < 0
                                ? 0
                                : this.state.finalAmount / 100}{" "}
                              THB
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item align="center" xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={9}>
                          <TextField
                            error={this.state.isInvalidCode}
                            helperText={
                              this.state.isInvalidCode && "Code is invalid, expired or reached the limit."
                            }
                            name="code"
                            label="Discount code"
                            variant="outlined"
                            type="input"
                            value={this.state.code}
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              height: "100%",
                            }}
                            onChange={this.handleChange}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{
                              marginTop: "20px",
                              width: "100%",
                              backgroundColor: "#76323F",
                            }}
                            onClick={() => {
                              this.handleApplyCouponCode(this.state.code);
                            }}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {this.state.isAgreed && (
                      <Grid
                        item
                        align="center"
                        xs={12}
                        style={{ marginTop: "30%" }}
                      >
                        {this.state.finalAmount / 100 <= 0 ? (
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              backgroundColor: "#76323F",
                              textTransform: "none",
                            }}
                            onClick={() => {
                              this.handleFreePaid(
                                JSON.parse(localStorage.getItem("user")).email,
                                JSON.parse(localStorage.getItem("user")).name
                              );
                            }}
                          >
                            Purchase a test
                          </Button>
                        ) : (
                          <CheckoutCreditCard
                            user={JSON.parse(localStorage.getItem("user"))}
                            amount={this.state.finalAmount}
                            createCreditCardCharge={this.createCreditCardCharge}
                          />
                        )}
                      </Grid>
                    )}
                    {!this.state.isAgreed && (
                      <Grid
                        item
                        align="center"
                        xs={12}
                        style={{ marginTop: "30%" }}
                      >
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          //className="btn"
                          type="button"
                          id="credit-card"
                          onClick={this.handleOpenAgreement}
                          style={{
                            width: "100%",
                            backgroundColor: "#76323F",
                            padding: "10px",
                          }}
                        >
                          <Typography
                            variant="button"
                            style={{ textTransform: "none" }}
                          >
                            Agreement & Copyright Acknowledgement
                          </Typography>
                        </Button>
                      </Grid>
                    )}

                    {/*this.state.isAgreed && (
                    <Grid
                      item
                      align="center"
                      xs={12}
                      style={{ marginTop: "2%" }}
                    >
                      <PayPal
                        course={this.props.course}
                        amount={this.state.finalAmount}
                      />
                    </Grid>
                  )*/}
                  </Grid>
                </CardContent>
                <CardActions>
                  <Link
                    to={"/ourtests"}
                    color="inherit"
                    underline="none"
                    style={{ textDecoration: "none", color: "#565656" }}
                  >
                    <Button color="primary" style={{ color: "#76323F" }}>
                      Back
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.state.isPaid && (
            <PaymentSuccessfulPage
              purchasedId={this.state.purchasedId}
              email={this.state.email}
              name={this.state.name}
              amount={this.state.finalAmount / 100}
              omiseToken={this.state.omiseToken}
              phone={"+" + JSON.parse(localStorage.getItem("user")).phone}
              testType={this.state.courseName}
            />
          )}

          {this.state.isScheduleSpeaking && (
            <TestSchedulerPage
              user={JSON.parse(localStorage.getItem("user"))}
              purchasedId={this.state.purchasedId}
              handleEndScheduleSpeaking={this.handleEndScheduleSpeaking}
            />
          )}
        </Grid>
      </Grid>
    );

    const webUI = (
      <Grid
        container
        spacing={5}
        justify="center"
        style={{
          marginTop: "50px",
          marginBottom: "10%",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <PageBackdrop isLoading={this.state.isLoading} />
        <Dialog
          open={this.state.isOpenAgreement}
          onClose={this.handleCloseAgreement}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"AGREEMENT & COPYRIGHT ACKNOWLEDGEMENT"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div dangerouslySetInnerHTML={{ __html: this.state.activeStep === 0 ? termsAndAgreements.termsAndAgreement : purchasePopup.purchasePopup }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12} align="center">
                <MobileStepper
                  variant="dots"
                  steps={2}
                  position="static"
                  activeStep={this.state.activeStep}
                  nextButton={
                    <Button size="small" onClick={this.handleNext} disabled={this.state.activeStep === 1}>
                      Next
                      <KeyboardArrowRight />
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                      <KeyboardArrowLeft />
                      Back
                    </Button>
                  }
                />
              </Grid>
              <Grid item xs={12} align="right">
                <Button onClick={this.handleCloseAgreement} style={{ color: "grey", marginRight: "10px" }}>
                  Disagree
                </Button>
                <Button
                  disableElevation
                  onClick={this.handleConfirmAgreement}
                  variant="contained"
                  style={{ backgroundColor: "#76323F", color: "white" }}
                  autoFocus
                >
                  Agree
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        {!this.state.isPaid && !this.state.isScheduleSpeaking && (
          <Snackbar
            open={this.state.isError}
            autoHideDuration={6000}
            onClose={this.handleCloseSnackBar}
          >
            <Alert onClose={this.handleCloseSnackBar} severity="error">
              Payment was unsuccessful. Your credit card was not charged.
            </Alert>
          </Snackbar>
        )}
        {!this.state.isPaid && !this.state.isScheduleSpeaking && (
          <Grid item align="left" xs={12}>
            <Typography variant="h4" style={{ fontWeight: "bold" }} gutterBottom>
              Payment method
            </Typography>
          </Grid>
        )}
        {!this.state.isPaid && !this.state.isScheduleSpeaking && (
          <Grid item align="center" xs={12} sm={8}>
            <Paper style={{ padding: "50px" }}>
              <Grid container spacing={2}>
                <Grid item align="left" xs={12}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }} gutterBottom>
                    Customer information
                  </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    defaultValue={JSON.parse(localStorage.getItem("user")).email ? JSON.parse(localStorage.getItem("user")).email : ""}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item align="left" xs={12} style={{ marginTop: "5%" }}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }} gutterBottom>
                    Billing address
                  </Typography>
                </Grid>
                <Grid item align="left" xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="First name"
                        variant="outlined"
                        defaultValue={JSON.parse(localStorage.getItem("user")).firstname}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="Last name"
                        variant="outlined"
                        defaultValue={JSON.parse(localStorage.getItem("user")).lastname}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item align="left" xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Company (optional)"
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item align="left" xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={7}>
                      <TextField
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="outlined-basic"
                        label="Apt, suite, etc. (optional)"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item align="left" xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item align="left" xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="Country"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="Postal code"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item align="left" xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Phone"
                    variant="outlined"
                    style={{ width: "100%" }}
                    defaultValue={"+" + JSON.parse(localStorage.getItem("user")).phone}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        {!this.state.isPaid && !this.state.isScheduleSpeaking && (
          <Grid item align="right" xs={12} sm={4}>
            <Card style={{ padding: "30px" }}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Make a payment
                </Typography>
                <Typography color="textSecondary">Powered by Omise</Typography>
                <Grid container justify="center">
                  <Grid item align="center" xs={12}>
                    <Grid container justify="center">
                      <Grid item align="left" xs={6}>
                        <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                          Name:
                        </Typography>
                      </Grid>
                      <Grid item align="right" xs={6}>
                        <Typography
                          variant="body1"
                          color="primary"
                          gutterBottom
                          style={{ color: "#76323F", fontWeight: "bold" }}
                        >
                          {this.state.courseName}
                        </Typography>
                      </Grid>
                      <Grid item align="left" xs={6}>
                        <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                          Description:
                        </Typography>
                      </Grid>
                      <Grid item align="right" xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {this.state.description}
                        </Typography>
                      </Grid>
                      <Grid item align="left" xs={6}>
                        <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                          Amount:
                        </Typography>
                      </Grid>
                      <Grid item align="right" xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {this.state.originalAmount / 100} THB
                        </Typography>
                      </Grid>
                      {this.state.isApplyCode && (
                        <Grid item align="left" xs={6}>
                          <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                            Discount:
                          </Typography>
                        </Grid>
                      )}
                      {this.state.isApplyCode && (
                        <Grid item align="right" xs={6}>
                          <Typography
                            variant="body1"
                            style={{ color: "#80c904" }}
                            gutterBottom
                          >
                            -{" "}
                            {this.state.discount > this.state.originalAmount
                              ? this.state.originalAmount / 100
                              : this.state.discount / 100}{" "}
                            THB
                          </Typography>
                        </Grid>
                      )}
                      {this.state.isApplyCode && (
                        <Grid item align="left" xs={6}>
                          <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
                            Total amount:
                          </Typography>
                        </Grid>
                      )}
                      {this.state.isApplyCode && (
                        <Grid item align="right" xs={6}>
                          <Typography variant="body1" gutterBottom>
                            {this.state.finalAmount < 0
                              ? 0
                              : this.state.finalAmount / 100}{" "}
                            THB
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item align="center" xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={9}>
                        <TextField
                          error={this.state.isInvalidCode}
                          helperText={
                            this.state.isInvalidCode && "Code is invalid, expired or reached the limit."
                          }
                          name="code"
                          label="Discount code"
                          variant="outlined"
                          type="input"
                          value={this.state.code}
                          style={{
                            marginTop: "10px",
                            width: "100%",
                            height: "100%",
                          }}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: "20px",
                            width: "100%",
                            backgroundColor: "#76323F",
                          }}
                          onClick={() => {
                            this.handleApplyCouponCode(this.state.code);
                          }}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.isAgreed && (
                    <Grid
                      item
                      align="center"
                      xs={12}
                      style={{ marginTop: "30%" }}
                    >
                      {this.state.finalAmount / 100 <= 0 ? (
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: "10px",
                            width: "100%",
                            backgroundColor: "#76323F",
                            textTransform: "none",
                          }}
                          onClick={() => {
                            this.handleFreePaid(
                              JSON.parse(localStorage.getItem("user")).email,
                              JSON.parse(localStorage.getItem("user")).name
                            );
                          }}
                        >
                          Purchase a test
                        </Button>
                      ) : (
                        <CheckoutCreditCard
                          user={JSON.parse(localStorage.getItem("user"))}
                          amount={this.state.finalAmount}
                          createCreditCardCharge={this.createCreditCardCharge}
                        />
                      )}
                    </Grid>
                  )}
                  {!this.state.isAgreed && (
                    <Grid
                      item
                      align="center"
                      xs={12}
                      style={{ marginTop: "30%" }}
                    >
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        //className="btn"
                        type="button"
                        id="credit-card"
                        onClick={this.handleOpenAgreement}
                        style={{
                          width: "100%",
                          backgroundColor: "#76323F",
                          padding: "10px",
                        }}
                      >
                        <Typography
                          variant="button"
                          style={{ textTransform: "none" }}
                        >
                          Agreement & Copyright Acknowledgement
                        </Typography>
                      </Button>
                    </Grid>
                  )}

                  {/*this.state.isAgreed && (
                    <Grid
                      item
                      align="center"
                      xs={12}
                      style={{ marginTop: "2%" }}
                    >
                      <PayPal
                        course={this.props.course}
                        amount={this.state.finalAmount}
                      />
                    </Grid>
                  )*/}
                </Grid>
              </CardContent>
              <CardActions>
                <Link
                  to={"/ourtests"}
                  color="inherit"
                  underline="none"
                  style={{ textDecoration: "none", color: "#565656" }}
                >
                  <Button color="primary" style={{ color: "#76323F" }}>
                    Back
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        )}

        {this.state.isPaid && (
          <PaymentSuccessfulPage
            purchasedId={this.state.purchasedId}
            email={this.state.email}
            name={this.state.name}
            amount={this.state.finalAmount / 100}
            omiseToken={this.state.omiseToken}
            phone={"+" + JSON.parse(localStorage.getItem("user")).phone}
            testType={this.state.courseName}
          />
        )}

        {this.state.isScheduleSpeaking && (
          <TestSchedulerPage
            user={JSON.parse(localStorage.getItem("user"))}
            purchasedId={this.state.purchasedId}
            handleEndScheduleSpeaking={this.handleEndScheduleSpeaking}
          />
        )}
      </Grid>
    );

    return (
      <ThemeProvider theme={theme}>
        <Snackbar open={this.state.isOpenSnackBar} autoHideDuration={10000} onClose={this.handleCloseSnackBar}>
          <Alert severity="error" onClose={this.handleCloseSnackBar}>Something went wrong. Please try again later.</Alert>
        </Snackbar>
        <Dialog
          open={this.state.isPopupBlocked}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Pop-up has been blocked by browser.
          </DialogTitle>
          <DialogContent>
            <DialogContentText ref={this.myRef} id="alert-dialog-description">
              To proceed with the payment, please ensure that pop-ups and redirects are enabled for this page. 
              This will allow the payment process to continue smoothly without any interruptions. Please click on 'Refresh' after enabling pop-ups.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.location.reload()} color="primary">
              Refresh
            </Button>
          </DialogActions>
        </Dialog>
        <Hidden mdUp>{mobileUI}</Hidden>
        <Hidden smDown>{webUI}</Hidden>
      </ThemeProvider>
    );
  }
}

export default compose(withStyles(useStyles))(ConfirmPaymentPage);
