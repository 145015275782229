//React
import { Component } from 'react';
import { compose } from 'recompose';

//Material UI
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//import components

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

class MultipleChoice extends Component {
    constructor() {
        super();

        this.state = {
            answerObj: {
                answers: {}
            },
            sizeMapHeader: {
                'sm': 'body2',
                'md': 'body1',
                'lg': 'h6',
            },
            sizeMapBody: {
                'sm': 'subtitle2',
                'md': 'body2',
                'lg': 'body1',
            },
            sizeMapCaption: {
                'sm': '0.7rem',
                'md': '0.8rem',
                'lg': '1rem',
            }
        };
    }

    componentDidMount = async () => {
        const filteredAnsObj = await this.props.answerObj?.parts[this.props.currentPart - 1].sections.find((element) => {
            return element.section_id === this.props.sectionInfo.section_id;
        })

        this.setState({
            answerObj: {
                section_id: this.props.sectionInfo.section_id,
                question_type: this.props.sectionInfo.question_type,
                answers: (filteredAnsObj.answers === undefined || Object.keys(filteredAnsObj.answers).length === 0) ? {} : filteredAnsObj.answers
            }
        })
    }

    handleAnswerQuestion = (event, questionNo) => {
        const questionKey = '' + questionNo //Convert integer to string
        this.state.answerObj.answers[questionKey] = event.target.value;
        this.props.handleAnswerObj(this.state.answerObj)
        this.props.handleAnsweredQuestionNumber(questionNo);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container style={{ marginBottom: "50px" }}>
                <Grid item xs={12}>
                    <Typography variant={this.state.sizeMapHeader[`${this.props.fontSizeMap}`]} gutterBottom align='left'>
                        <Box fontWeight="fontWeightBold" display='inline'>{this.props.sectionInfo.section}</Box>
                    </Typography>
                    <Typography variant={this.state.sizeMapBody[`${this.props.fontSizeMap}`]} gutterBottom align='left'>
                        Choose the correct answers.
                    </Typography>
                </Grid>
                {this.props.sectionInfo.questions.map((q, index) =>
                    <Grid item align='left' xs={12} onFocus={() => { this.props.onFocusQuestion(Number(q.no)) }}>
                        <Typography align='left' variant={this.state.sizeMapBody[`${this.props.fontSizeMap}`]} gutterBottom align='left' style={{ marginTop: index === 0 ? '10px' : '30px', fontWeight: 'bold' }}>
                            <Box fontWeight="fontWeightBold" display='inline' m={1}>{q.no}</Box> {q.question}
                        </Typography>
                        <RadioGroup
                            aria-label="q1"
                            name={this.props.currentPart + '_' + q.no}
                            value={this.state.answerObj.answers?.hasOwnProperty(Number(q.no)) ? this.state.answerObj.answers[Number(q.no)] : ''}
                            onChange={(event) => { this.handleAnswerQuestion(event, q.no) }}
                            placeholder={this.props.currentPart}
                            style={{ marginLeft: '20px' }}
                        >
                            {q.choice.map(choice =>
                                <FormControlLabel
                                    value={choice}
                                    control={<Radio color="default" />}
                                    label={<Typography style={{ fontSize: this.state.sizeMapCaption[`${this.props.fontSizeMap}`] }}>{choice}</Typography>}
                                />
                            )}
                        </RadioGroup>
                    </Grid>
                )}
            </Grid>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(MultipleChoice);