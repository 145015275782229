//Material UI
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { compose } from 'recompose';

//Icon
import InsertEmoticonRoundedIcon from '@material-ui/icons/InsertEmoticonRounded';

//Others
import { green } from '@material-ui/core/colors';
import axios from 'axios';
import { Component } from 'react';

//react-router-dom

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        padding: '50px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    radio: {
        '&$checked': {
            color: 'black'
        }
    }
});

const CustomRadio = withStyles({
    root: {
      '&$checked': {
        color: "#880000",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

class TestEvaluationPage extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isSubmitted: false,
            testSystemScore: 0,
            Q1: {
                question: "How satisfied were you with your experience on overall test system today?",
                rating: null
            },
            Q2: {
                question: "How much would you rate difficulty of this test?",
                rating: null
            },
            Q3: {
                question: "Do you want to recommend this test to your friends?",
                rating: null
            },
            additionalComment: ''
        }
    }

    componentDidMount = () => {
        document.body.style.overflow = "auto";
        const query = new URLSearchParams(this.props.location.search);
        const testId = query.get('test-id');
        const reportId = query.get('report-id');
        const testType = query.get('test-type');

        localStorage.setItem('readingSession', null);
        localStorage.setItem('listeningSession', null);
        localStorage.setItem('writingSession', null);
        localStorage.setItem('testSession', null);

        this.setState({
            testId: testId,
            reportId: reportId,
            testType: testType
        });
    }

    handleChangeScore = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangeQuestionRating = (event, questionNo) => {
        console.log(event.target.value);
        if (questionNo === 1) {
            this.state.Q1.rating = event.target.value
        } else if (questionNo === 2) {
            this.state.Q2.rating = event.target.value
        } else if (questionNo === 3) {
            this.state.Q3.rating = event.target.value
        }
        
        this.setState({
            Q1: this.state.Q1,
            Q2: this.state.Q2,
            Q3: this.state.Q3
        })
    }

    handleChangeAdditionalComment = (event) => {
        this.setState({
            additionalComment: event.target.value
        });
    }

    handleSubmitEvaluation = async () => {
        const { history } = this.props;

        try {
            const query = new URLSearchParams(this.props.location.search);
            const testType = query.get('test-type');
            this.handleOpenBackDrop();

            if (this.state.Q1.rating === null || this.state.Q2.rating === null || this.state.Q3.rating === null) {
                this.setState({
                    isErrorRequiredFields: true
                })

                this.handleCloseBackDrop();
                return;
            }

            await axios({
                method: 'post',
                url: 'https://chatter-funky-busby.glitch.me/test/evaluations/submit',
                data: {
                    submitterName: JSON.parse(localStorage.getItem('user')).name,
                    submitterUsername: JSON.parse(localStorage.getItem('user')).username,
                    evaluationObj: [
                        this.state.Q1,
                        this.state.Q2,
                        this.state.Q3
                    ],
                    additionalComment: this.state.additionalComment,
                    testType: testType,
                    createdAt: Date.now()
                }
            });

            this.setState({
                isSubmitted: true
            });
            this.handleCloseBackDrop();

            if (this.state.testType === 'IELTS Full Test') {
                history.push(`/full-report?result_id=${this.state.reportId}`);
                this.props.handleEndTest();
            } if (this.state.testType === 'BMAT') {
                history.push(`/full-bmat-report?result_id=${this.state.reportId}`);
                this.props.handleEndTest();
            } if (this.state.testType === 'IELTS READING FREE' || this.state.testType === 'IELTS LISTENING FREE' || this.state.testType === 'BMAT FREE') {
                history.push(`/report?result_id=${this.state.reportId}`);
                this.props.handleEndTest();
            }
        } catch (err) {
            console.log(err);
            this.handleCloseBackDrop();
        }
    }

    handleOpenBackDrop = () => {
        this.setState({
            isLoading: true
        });
    }

    handleCloseBackDrop = () => {
        this.setState({
            isLoading: false
        });
    }

    handleCloseSnackbar = () => {
        this.setState({
            isErrorRequiredFields: false
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid justify='center' container style={{ marginBottom: "285px" }}>
                <Grid item align='center' xs={12}>
                    <Avatar variant="square" src="/U Test Logo (new).png" style={{ width: '37px', height: '70px', margin: '20px' }} />
                </Grid>
                <Paper style={{ padding: '30px' }}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            Test Survey
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            Please take a minute to do a survey too see your report.
                        </Typography>
                    </Grid>
                    {this.state.isSubmitted &&
                        <Grid item xs={12}>
                            <InsertEmoticonRoundedIcon style={{ fontSize: 100, color: green[500] }} />
                        </Grid>
                    }
                    {this.state.isSubmitted &&
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                We appreciate your feedback.
                            </Typography>
                        </Grid>
                    }
                    {this.state.isSubmitted &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Thank you! Your form has been submitted successfully.
                            </Typography>
                        </Grid>
                    }
                    {!this.state.isSubmitted &&
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                            <Typography
                                align='left'
                                variant="body2"
                                gutterBottom
                                style={{ fontWeight: 'bold', color: (this.state.Q1.rating === null && this.state.isErrorRequiredFields) ? 'red' : '' }}
                            >
                                How satisfied were you with your experience on overall test system today?
                            </Typography>
                            <FormControl component="fieldset" error={this.state.Q1.rating === null && this.state.isErrorRequiredFields}>
                                <RadioGroup
                                    aria-label="q1"
                                    name="Q1"
                                    value={this.state.Q1.rating}
                                    onChange={(event) => { this.handleChangeQuestionRating(event, 1) }}
                                    row
                                >
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <Typography align='left' variant="body2" style={{ margin: '5px', marginRight: '20px' }}>
                                                Worst
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel value={'1'} control={<CustomRadio />} label="1" />
                                            <FormControlLabel value={'2'} control={<CustomRadio />} label="2" />
                                            <FormControlLabel value={'3'} control={<CustomRadio />} label="3" />
                                            <FormControlLabel value={'4'} control={<CustomRadio />} label="4" />
                                            <FormControlLabel value={'5'} control={<CustomRadio />} label="5" />
                                        </Grid>
                                        <Grid item>
                                            <Typography align='left' variant="body2" style={{ margin: '5px' }}>
                                                Best
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    }
                    {!this.state.isSubmitted &&
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Typography
                                align='left'
                                variant="body2"
                                gutterBottom
                                style={{ fontWeight: 'bold', color: (this.state.Q2.rating === null && this.state.isErrorRequiredFields) ? 'red' : '' }}
                            >
                                How much would you rate difficulty of this test?
                            </Typography>
                            <FormControl component="fieldset" error={this.state.Q2.rating === null && this.state.isErrorRequiredFields}>
                                <RadioGroup
                                    aria-label="q2"
                                    name="Q2"
                                    value={this.state.Q2.rating}
                                    onChange={(event) => { this.handleChangeQuestionRating(event, 2) }}
                                    row
                                >
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <Typography align='left' variant="body2" style={{ margin: '5px', marginRight: '20px' }}>
                                                Easy
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel value={'1'} control={<CustomRadio />} label="1" />
                                            <FormControlLabel value={'2'} control={<CustomRadio />} label="2" />
                                            <FormControlLabel value={'3'} control={<CustomRadio />} label="3" />
                                            <FormControlLabel value={'4'} control={<CustomRadio />} label="4" />
                                            <FormControlLabel value={'5'} control={<CustomRadio />} label="5" />
                                        </Grid>
                                        <Grid item>
                                            <Typography align='left' variant="body2" style={{ margin: '5px' }}>
                                                Difficult
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    }
                    {!this.state.isSubmitted &&
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Typography
                                align='left'
                                variant="body2"
                                gutterBottom
                                style={{ fontWeight: 'bold', color: (this.state.Q3.rating === null && this.state.isErrorRequiredFields) ? 'red' : '' }}
                            >
                                Do you want to recommend this test to your friends?
                            </Typography>
                            <FormControl component="fieldset" error={this.state.Q3.rating === null && this.state.isErrorRequiredFields}>
                                <RadioGroup
                                    aria-label="q3"
                                    name="Q3"
                                    value={this.state.Q3.rating}
                                    onChange={(event) => { this.handleChangeQuestionRating(event, 3) }}
                                    row
                                >
                                    <Grid container justify="center" alignItems='center'>
                                        <Grid item>
                                            <Typography align='left' variant="body2" style={{ margin: '5px', marginRight: '20px' }}>
                                                Disagree
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel value={'1'} control={<CustomRadio />} label="1" />
                                            <FormControlLabel value={'2'} control={<CustomRadio />} label="2" />
                                            <FormControlLabel value={'3'} control={<CustomRadio />} label="3" />
                                            <FormControlLabel value={'4'} control={<CustomRadio />} label="4" />
                                            <FormControlLabel value={'5'} control={<CustomRadio />} label="5" />
                                        </Grid>
                                        <Grid item>
                                            <Typography align='left' variant="body2" style={{ margin: '5px' }}>
                                                Agree
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    }
                    {!this.state.isSubmitted &&
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Typography align='left' variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                                Do you have any additional comments?
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={2}
                                onChange={this.handleChangeAdditionalComment}
                            />
                        </Grid>
                    }
                </Paper>
                {!this.state.isSubmitted &&
                    <Grid item align='center' xs={12} style={{ marginTop: '20px' }}>
                        <Button
                            size="small"
                            variant='text'
                            style={{ backgroundColor: '#880000' }}
                            onClick={this.handleSubmitEvaluation}
                        >
                            <Box fontWeight="fontWeightBold" display='inline' style={{ color: 'white' }}>Submit & see my report</Box>
                        </Button>
                    </Grid>
                }
                {/*!this.state.isSubmitted &&
                    <Grid item align='center' xs={12}>
                        <Link to="/profile" style={{ textDecoration: 'none', color: '#0f0f0f' }}>
                            <Button
                                size="small"
                                variant='text'
                            >
                                <Box fontWeight="fontWeightBold" display='inline' style={{ color: '#a6a6a6' }}>Skip</Box>
                            </Button>
                        </Link>
                    </Grid>
                */}
                <Backdrop className={classes.backdrop} open={this.state.isLoading} onClick={this.handleCloseBackDrop}>
                    <CircularProgress color="inherit" style={{ color: "#880000" }} />
                </Backdrop>

                <Snackbar open={this.state.isErrorRequiredFields} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
                    <Alert onClose={this.handleCloseSnackbar} severity="error">
                        Please fill in all required fields.
                    </Alert>
                </Snackbar>
            </Grid>
        )
    }
}
export default compose(
    withStyles(useStyles)
)(TestEvaluationPage);
