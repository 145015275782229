//React
import { Component } from 'react';
import { compose } from 'recompose';
import { Prompt } from 'react-router-dom';

//Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//import components
import VerificationPage from '../../verification/VerificationPage';
import TestListening from './tests/TestListenting';
import ListeningRender from './tests/ListeningRender';
import ReadingRender from './tests/ReadingRender';
import WritingRender from './tests/WritingRender';
import TestReading from './tests/TestReading';
import TestWriting from './tests/TestWriting';

//Dialogs
import RegistrationFormDialog from "../../dialogs/RegistrationFormDialog";

import MediaQuery from 'react-responsive';
import ListeningRetina from './tests/ListeningRetina';
import TestListenting from './tests/TestListenting';
import { CreateTestSurveyLog } from '../../../../services/testServices';
import { Button } from '@material-ui/core';

const axios = require('axios').default;
const api_base_url = "https://e-learning-be-ybcs6wa7da-as.a.run.app";

//FONT RELATED
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Noto Sans'
        ].join(','),
    },
});

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class FullTestMain extends Component {
    constructor() {
        super();
        this.state = {
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            readingAnwerObj: {},
            listeningAnwerObj: {},
            isLoading: true,
            isOpenDialog: true,
            isContinueTablet: false,
            isDoneReport: false,
            answerObj: {
                parts: []
            },
            purchaseId: "",
            centralTestId: ""
        };
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#efefef";
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        const query = new URLSearchParams(this.props.location.search);
        const courseId = query.get('id');

        this.setState({
            purchaseId: courseId
        });

        this.props.handleStartTest();



        await this.checkSession();
        // await this.handleGetPurchasedCourse();
        // await this.handleCheckCourseId();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    checkSession = async () => {
        if (JSON.parse(localStorage.getItem("testSession")) !== null) {
            this.setState(JSON.parse(localStorage.getItem("testSession")));
            this.handleStartPurchasedTest();
        } else {
            await this.handleGetPurchasedCourse();
            await this.handleCheckCourseId();
        }

        if (this.state.regitrationFormResult === undefined
            || Object.keys(this.state.regitrationFormResult).length === 0) {
            if (JSON.parse(localStorage.getItem("testSession")).regitrationFormResult === undefined
                || Object.keys(JSON.parse(localStorage.getItem("testSession")).regitrationFormResult).length === 0) {
                this.setState({
                    regitrationFormResult: undefined,
                    isOpenDialog: true
                });
            } else {
                this.setState({
                    regitrationFormResult: JSON.parse(localStorage.getItem("testSession")).regitrationFormResult,
                    isOpenDialog: false
                });
            }
        }
    }

    handleSetCentralTestId = (testId) => {
        this.setState({
            centralTestId: testId
        });
    }

    handleResize = (event) => {
        this.setState({
            window: {
                height: window.innerHeight,
                width: window.innerWidth
            }
        });
    };

    handleGetPurchasedCourse = async () => {
        try {
            const purchasedCourse = await axios({
                method: 'get',
                url: api_base_url + "/api/test/test-purchase-status?user_name=" + JSON.parse(localStorage.getItem('user')).username,
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            })

            this.setState({
                purchasedCourse: purchasedCourse.data,
                startTestTime: new Date()
            });

        } catch (error) {
            console.error(error);
            this.setState({
                purchasedCourse: []
            });
        }
    }

    handleCheckCourseId = async () => {
        const query = new URLSearchParams(this.props.location.search);
        const courseId = query.get('id');
        const availableCourse = await this.state.purchasedCourse.filter(test => test._id === courseId && !test.tested);

        if (Boolean(availableCourse.length)) {
            this.setState({
                purchasedTestId: availableCourse[0]._id,
                currentTest: 'LISTENING',
                isLoading: false
            })
        }

        this.setState({
            isChecked: Boolean(availableCourse.length)
        })
    }

    handleStartPurchasedTest = async () => {
        try {
            const res = await axios({
                method: 'put',
                url: api_base_url + '/api/test/test-purchase-status?test_id=' + this.state.testId + '&test_status=true',
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });

            const resData = res.data;
        } catch (err) {
            console.log(err);
        }
    }

    storeSessionInDB = async (session) => {
        console.log(session);

        try {
            await axios({
                method: 'get',
                url: "https://chatter-funky-busby.glitch.me/test/status/logs/" + this.state.purchaseId
            });

            axios({
                method: 'put',
                url: 'https://chatter-funky-busby.glitch.me/test/status/log',
                data: {
                    log_id: this.state.purchaseId,
                    answerObj: this.state.answerObj,
                    answeredQuestion: session.answeredQuestion,
                    timer: session.timer,
                    timerSeconds: session.timerSeconds
                }
            })
        } catch (err) {
            await axios({
                method: 'post',
                url: 'https://chatter-funky-busby.glitch.me/test/status/log',
                data: {
                    username: JSON.parse(localStorage.getItem('user')).username,
                    test_id: "temp-id",
                    log_id: this.state.purchaseId,
                    test_type: 'IELTS',
                    test_part: 'FULL',
                    answerObj: this.state.answerObj,
                    answeredQuestion: 'N/A',
                    timer: 0,
                    timerSeconds: 0
                }
            });
        }
    }


    keepCurrentSession = (session) => {
        this.storeSessionInDB(session);

        if (this.state.currentTest === "LISTENING") {
            localStorage.setItem('listeningSession', JSON.stringify(session));
            localStorage.setItem('testSession', JSON.stringify(this.state));
        } if (this.state.currentTest === "READING") {
            localStorage.setItem('readingSession', JSON.stringify(session));
            localStorage.setItem('testSession', JSON.stringify(this.state));
        } if (this.state.currentTest === "WRITING") {
            localStorage.setItem('writingSession', JSON.stringify(session));
            localStorage.setItem('testSession', JSON.stringify(this.state));
        }

        localStorage.setItem('partSession', JSON.stringify(session));
        localStorage.setItem('testSession', JSON.stringify(this.state));
    }

    handleUpdateAnswerObj = (obj) => {
        if (this.state.answerObj.test_id === undefined) {
            this.state.answerObj['test_id'] = obj.test_id
            obj.parts.map(answer => {
                this.state.answerObj.parts.push(answer);
            })
        }

        else if (this.state.answerObj.test_id !== undefined) {
            obj.parts.map(answer => {
                this.state.answerObj.parts.push(answer);
            });
        }
    }

    handleSubmitResult = async (type) => {
        try {
            const res = await axios({
                method: 'post',
                url: `${api_base_url}/api/test/report?part=${type}`,
                headers: {
                    Authorization: `bearer ${JSON.parse(localStorage.getItem('user')).token}`
                },
                data: this.state.answerObj
            });

            return res.data
            //this.handleCreateStudentReport(res.data);
        } catch (err) {
            console.log(err);
        }
    }

    handleCreateStudentReport = async (obj) => {
        try {
            const { history } = this.props;

            const readingObj = await this.handleSubmitResult("READING");
            const listeningObj = await this.handleSubmitResult("LISTENING");

            readingObj.total_score = readingObj.total_score + listeningObj.total_score;
            readingObj.total_question = 80;
            readingObj['writing'] = {};
            readingObj['speaking'] = {};
            await readingObj.parts.push(...listeningObj.parts);

            const student_report = await axios({
                method: 'post',
                url: `${api_base_url}/api/test/report/student`,
                headers: {
                    Authorization: `bearer ${JSON.parse(localStorage.getItem('user')).token}`
                },
                data: readingObj
            });

            student_report.data["writingStatus"] = "submitted";
            student_report.data["speakingStatus"] = "empty";
            student_report.data["test_started"] = this.state.startTestTime;
            student_report.data["test_survey"] = this.state.regitrationFormResult;
            student_report.data["test_purchase_id"] = this.state.purchaseId;

            const resResult = await axios({
                method: 'post',
                url: `${api_base_url}/api/test/result`,
                headers: {
                    Authorization: `bearer ${JSON.parse(localStorage.getItem('user')).token}`
                },
                data: {
                    test_type: student_report.data.test_type,
                    test_result: student_report.data,
                    test_id: student_report.data.test_id,
                    username: JSON.parse(localStorage.getItem('user')).username
                }
            });

            CreateTestSurveyLog({
                user_email: JSON.parse(localStorage.getItem('user')).email,
                user_name: JSON.parse(localStorage.getItem('user')).username,
                survey_result: this.state.regitrationFormResult,
                test_type: student_report.data.test_type,
                test_id: student_report.data.test_id,
                purchase_id: this.state.purchaseId,
                result_id: resResult.data.result_id
            });

            this.setState({
                isDoneReport: true
            });

            return resResult.data;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    handleEndTest = async () => {
        try {
            const res = await axios({
                method: 'put',
                url: api_base_url + '/api/test/test-purchase-status?test_id=' + this.state.purchasedTestId + '&test_status=true',
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleStartTestType = (type) => {
        this.setState({
            currentTest: type
        });
    }

    handleCloseLoading = () => {
        this.setState({
            isLoading: false
        })
    }

    handleOpenLoading = () => {
        this.setState({
            isLoading: true
        })
    }

    //================================================================= CREATE TEST REPORT =================================================================
    handleGetTestReport = async () => {
        try {
            this.handleLoading();

            const testResult = await axios({
                method: 'post',
                url: api_base_url + "/api/test/report",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: this.state.answerObj
            })

            this.setState({
                testReportObj: testResult.data
            });

            this.handleCloseLoading();

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    handleGetStudentReport = async () => {
        try {
            this.handleLoading();

            const studentReport = await axios({
                method: 'post',
                url: api_base_url + "/api/test/report/student",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: this.state.testReportObj
            })

            this.setState({
                studentReport: studentReport.data
            });

            this.handleCloseLoading();

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    handleCreateReportResult = async () => {
        try {
            this.handleLoading();

            const bodyObj = {
                test_type: "IELTS",
                test_result: this.state.testReportObj,
                test_id: this.state.testReportObj.test_id,
                username: JSON.parse(localStorage.getItem('user')).username
            }

            const studentReport = await axios({
                method: 'post',
                url: api_base_url + "/api/test/result",
                headers: {
                    Authorization: "bearer " + JSON.parse(localStorage.getItem('user')).token
                },
                data: bodyObj
            })

            this.handleCloseLoading();

        } catch (error) {
            this.handleCloseLoading();
            console.error(error);
        }
    }

    // ======== Registration form =========
    onSubmitRegitrationFormResult = (result) => {
        this.setState({
            regitrationFormResult: result,
            isOpenDialog: false
        });
    }

    onClickContinueOnTablet = () => {
        this.setState({
            isContinueTablet: true
        });
    }

    onClickBackToHome = () => {
        const { history } = this.props;
        history.push("/profile");
    }

    render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Container
                    maxWidth={false}
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                    onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                    style={{ flex: 1, width: '100%' }}
                >
                    {!this.state.isDoneReport && <Prompt
                        when={true}
                        message="Leaving now? Your tests will be erased, and no refunds. Choose 'Cancel' to return or 'OK' to proceed."
                    />}

                    {this.state.isLoading &&
                        <Grid container>
                            <Grid item xs={12}>
                                <CircularProgress style={{ marginTop: '25%', marginBottom: '35%' }} />
                            </Grid>
                        </Grid>
                    }

                    {this.state.isChecked && <MediaQuery maxWidth="1224px">
                        {(matches) =>
                            <Dialog
                                open={!this.state.isContinueTablet && matches}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">Are you intending to take the test on a tablet device?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        We want to let you know that certain functionalities,text highlighting and note-taking, may be unavailable in tablet mode. For the optimal testing experience, we recommend using a desktop or laptop computer.
                                        <p />
                                        <p>If you wish to proceed with tablet mode, please click 'Continue'</p>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.onClickBackToHome} variant="contained" style={{ backgroundColor: "#a6a6a6", color: "white", textTransform: "none" }}>
                                        Back
                                    </Button>
                                    <Button onClick={this.onClickContinueOnTablet} variant="contained" style={{ backgroundColor: "#25333e", color: "white", textTransform: "none" }}>
                                        Continue
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }
                    </MediaQuery>}

                    {this.state.isChecked && <MediaQuery maxWidth="1224px">
                        {(matches) =>
                            ((this.state.isContinueTablet || !matches)) && <RegistrationFormDialog isOpenDialog={this.state.isOpenDialog} onSubmitRegitrationFormResult={this.onSubmitRegitrationFormResult} />
                        }
                    </MediaQuery>}

                    {!this.state.isChecked && !this.state.isLoading &&
                        <Grid container>
                            <Grid item xs={12} style={{ marginTop: '6%', marginBottom: '80%' }}>
                                <Alert severity="error">Invalid course. Please make sure that you pick an availabke course.</Alert>
                            </Grid>
                        </Grid>
                    }

                    {this.state.isChecked && this.state.currentTest === 'VERIFICATION' &&
                        <VerificationPage
                            user={this.props.user}
                            userSignup={this.props.userSignup}
                            userSignedin={this.props.userSignedin}
                            handleEndTest={this.props.handleEndTest}
                            handleEndTestSession={this.handleEndTest}
                            handleOpenLoading={this.handleOpenLoading}
                            handleCloseLoading={this.handleCloseLoading}
                            handleStartTest={this.props.handleStartTest}
                            handleStartTestType={this.handleStartTestType}
                            handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                        />
                    }

                    {this.state.isChecked && this.state.currentTest === 'READING' &&
                        <ReadingRender
                            session={JSON.parse(localStorage.getItem("readingSession"))}
                            user={this.props.user}
                            userSignup={this.props.userSignup}
                            userSignedin={this.props.userSignedin}
                            handleEndTest={this.props.handleEndTest}
                            keepCurrentSession={this.keepCurrentSession}
                            handleOpenLoading={this.handleOpenLoading}
                            handleCloseLoading={this.handleCloseLoading}
                            handleStartTest={this.props.handleStartTest}
                            handleStartTestType={this.handleStartTestType}
                            handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                            history={this.props.history}
                            centralTestId={this.state.centralTestId}
                        />
                    }
                    {this.state.isChecked && this.state.currentTest === 'LISTENING' &&
                        // <ListeningRender
                        //     user={this.props.user}
                        //     userSignup={this.props.userSignup}
                        //     userSignedin={this.props.userSignedin}
                        //     handleEndTest={this.props.handleEndTest}
                        //     handleEndTestSession={this.handleEndTest}
                        //     keepCurrentSession={this.keepCurrentSession}
                        //     handleOpenLoading={this.handleOpenLoading}
                        //     handleCloseLoading={this.handleCloseLoading}
                        //     handleStartTest={this.props.handleStartTest}
                        //     handleStartTestType={this.handleStartTestType}
                        //     handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                        //     history={this.props.history}
                        // />
                        <MediaQuery minResolution={"2dppx"}>
                            {(matches) =>
                                matches ? <ListeningRetina
                                    session={JSON.parse(localStorage.getItem("listeningSession"))}
                                    handleCreateStudentReport={this.handleCreateStudentReport}
                                    isLoading={this.state.isLoading}
                                    user={this.props.user}
                                    userSignup={this.props.userSignup}
                                    userSignedin={this.props.userSignedin}
                                    handleEndTest={this.props.handleEndTest}
                                    handleEndTestSession={this.handleEndTest}
                                    keepCurrentSession={this.keepCurrentSession}
                                    handleStartPurchasedTest={this.handleStartPurchasedTest}
                                    handleOpenLoading={this.handleOpenLoading}
                                    handleCloseLoading={this.handleCloseLoading}
                                    handleStartTest={this.props.handleStartTest}
                                    handleStartTestType={this.handleStartTestType}
                                    handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                                    handleSetCentralTestId={this.handleSetCentralTestId}
                                    isFreeTest={false}
                                    history={this.props.history}
                                /> : <TestListenting
                                    session={JSON.parse(localStorage.getItem("listeningSession"))}
                                    handleCreateStudentReport={this.handleCreateStudentReport}
                                    isLoading={this.state.isLoading}
                                    user={this.props.user}
                                    userSignup={this.props.userSignup}
                                    userSignedin={this.props.userSignedin}
                                    handleEndTest={this.props.handleEndTest}
                                    handleEndTestSession={this.handleEndTest}
                                    keepCurrentSession={this.keepCurrentSession}
                                    handleOpenLoading={this.handleOpenLoading}
                                    handleCloseLoading={this.handleCloseLoading}
                                    handleStartPurchasedTest={this.handleStartPurchasedTest}
                                    handleStartTest={this.props.handleStartTest}
                                    handleStartTestType={this.handleStartTestType}
                                    handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                                    handleSetCentralTestId={this.handleSetCentralTestId}
                                    isFreeTest={false}
                                    history={this.props.history}
                                />
                            }
                        </MediaQuery>
                    }
                    {this.state.isChecked && this.state.currentTest === 'WRITING' &&
                        <WritingRender
                            session={JSON.parse(localStorage.getItem("writingSession"))}
                            purchasedTestId={this.state.purchasedTestId}
                            purchaseId={this.state.purchaseId}
                            user={this.props.user}
                            userSignup={this.props.userSignup}
                            userSignedin={this.props.userSignedin}
                            handleEndTest={this.props.handleEndTest}
                            keepCurrentSession={this.keepCurrentSession}
                            handleOpenLoading={this.handleOpenLoading}
                            handleCloseLoading={this.handleCloseLoading}
                            handleStartTest={this.props.handleStartTest}
                            handleStartTestType={this.handleStartTestType}
                            handleUpdateAnswerObj={this.handleUpdateAnswerObj}
                            handleSubmitResult={this.handleSubmitResult}
                            handleCreateStudentReport={this.handleCreateStudentReport}
                            history={this.props.history}
                        />
                    }
                </Container>
            </ThemeProvider>
        )
    }
}

export default compose(
    withStyles(useStyles)
)(FullTestMain);
