import * as apiConstants from '../constants/apiConstants';

const axios = require('axios').default;

export async function DeleteUser(token, username) {
    try {
        const res = await axios({
            method: 'delete',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/user/delete/${username}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

//=========================== REGISTER NEW USER ======================================
export async function CreateNewUser(token, userObj) {
    try {
        await axios({
            method: 'post',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/user`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: userObj
        });

        const res = await axios({
            method: 'put',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/user`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: userObj
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function UpdateUserInfo(userObj) {
    try {
        const res = await axios({
            method: 'put',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/users/username/${userObj.username}`,
            data: userObj
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function UpdateUserInfoSecure(token, userObj) {
    try {
        const res = await axios({
            method: 'put',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/user`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: userObj
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}


//=========================== REGISTER NEW USER ======================================

//=========================== CHECK EXISTING ======================================
export async function IsExistingEmail(token, email) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/user/exist?email=${email}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}
//=========================== CHECK EXISTING ======================================

//=========================== REGISTER NEW USER/LOGIN ======================================
export async function Login(loginObj) {
    try {
        const token = `${loginObj.username}:${loginObj.password}`;
        const encodedToken = Buffer.from(token).toString('base64');

        const res = await axios({
            method: 'post',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/oauth/login`,
            headers: {
                "Authorization": `Basic ${encodedToken}`
            }
        });

        const userDetailRes = await GetUserDetail(res.data.token.token);

        return {
            success: true,
            res: userDetailRes.res,
            token: userDetailRes.token
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function LoginWithGoogle(googleToken) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/oauth/login/google`,
            headers: {
                "id_token": googleToken
            }
        });

        const userDetailRes = await GetUserDetail(res.data.token.token);

        return {
            success: true,
            res: userDetailRes.res,
            token: userDetailRes.token
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetUserDetail(token) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/user`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data,
            token: token
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetAllUsers(token) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/user/all`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}
//=========================== REGISTER NEW USER/LOGIN ======================================

export async function GetAllTestHistory(username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/test/status/logs?username=${username}`
        });

        let tempArr = [];
        await res.data.logs.map(async (test, index) => {
            const isNotExpired = await handleFilterExpiredTest(test.createdAt);
            if (isNotExpired) {
                tempArr.push(test);
            }
        })

        const sortedArr = await tempArr.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        return {
            success: true,
            res: sortedArr
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function GetAllTestLogs(username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/test/status/logs?username=${username}`
        });

        const filteredLogs = res.data.logs.filter((log) => { return log.isDone });
        const sortedArr = await filteredLogs.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        return {
            success: true,
            res: sortedArr
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

export async function handleFilterExpiredTest(date) {
    const oneHour = 60 * 60 * 1000;
    return !(((new Date()) - new Date(date)) > oneHour);
}

//===================================== TEST RELATED ===========================================
export async function GetAllPurchasedTests(token, username) {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.E_LEARNING_API_BASE_URL}/api/test/test-purchase-status?user_name=${username}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}
//===================================== TEST RELATED ===========================================

//================ TEACHER ==================
export async function GetAllTeachers() {
    try {
        const res = await axios({
            method: 'get',
            url: `${apiConstants.TEST_MANAGEMENT_API_BASE_URL}/teachers`
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}

// CONTACT US
export async function SubmitEmailContact(body) {
    try {
        const res = await axios({
            method: 'post',
            url: `${apiConstants.SPEAKING_API_BASE_URL}/contact/submit`,
            data: body
        });

        return {
            success: true,
            res: res.data
        }
    } catch (err) {
        console.log(err);
        return {
            success: false,
            res: err
        }
    }
}
